import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bxs-dashboard',
        link: '/dashboard',
        view: ['user','provider']
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bxs-dashboard',
        link: '/dashboard/home',
        view: ['first']
    },
    {
        id: 3,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bxs-dashboard',
        link: '/dashboard/admin',
        view: ['admin']
    },
    {
      id:13,
      label:'MENUITEMS.PREREQUISITE.TEXT',
      icon:'bxs-info-circle',
      link:'/info',
      view:['user','first']
    },
    {
        id: 5,
        label: 'MENUITEMS.INFORMATION.TEXT',
        icon: 'bxs-plus-circle',
        link: '/information/service',
        view: ['user', 'first']
    },
    {
        id: 6,
        label: 'MENUITEMS.USERS.TEXT',
        icon: 'bxs-user',
        link: '/users',
        view: ['admin']
    },
    {
        id: 4,
        label: 'MENUITEMS.PROJECTS.TEXT',
        icon: 'bxs-food-menu',
        link: '/projects',
        view: ['admin', 'user','provider']
    },
    
    // {
    //     id: 7,
    //     label: 'MENUITEMS.USERTYPE.TEXT',
    //     icon: 'bxs-group',
    //     link: '/usertype',
    //     view: ['admin']
    // },
    {
        id: 8,
        label: 'MENUITEMS.PROVIDERS.TEXT',
        // svgIcon: '../assets/svg/user-doctor-solid.svg',
        faicon: 'fa-solid fa-user-doctor',
        icon: '',
        link: '/providers',
        view: ['admin']
    },
    // {
    //     id: 9,
    //     label: 'MENUITEMS.PAYERS.TEXT',
    //     icon: 'bx-money',
    //     link: '/payers',
    //     view: ['admin']
    // },
    // {
    //     id: 10,
    //     label: 'MENUITEMS.SERVICES.TEXT',
    //     icon: 'bx-file',
    //     link: '/services',
    //     view: ['admin']
    // },
    // {
    //     id: 11,
    //     label: 'MENUITEMS.INVOICES.TEXT',
    //     icon: 'bxs-file-doc',
    //     link: '/invoices',
    //     view: ['admin', 'user']
    // },
    // {
    //     id: 12,
    //     label: 'MENUITEMS.CORRESPONDENCES.TEXT',
    //     icon: 'bx-link',
    //     link: '/correspondences',
    //     view: ['admin', 'user']
    // },

    // {
    //     id: 1,
    //     label: 'MENUITEMS.DASHBOARDMAPPING.TEXT',
    //     icon: 'bx-link',
    //     link: '/dashboardMapping',
    //     view: ['admin']
    // },
    {
        id: 13,
        label: 'MENUITEMS.SETTINGS.TEXT',
        icon: 'bxs-cog',
        link: '/settings',
        view: ['admin', 'user', 'first','provider']
    }
];

