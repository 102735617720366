<header id="page-topbar">
  <div class="white_background"></div>
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->

      <!-- <span style="font-size:30px;cursor:pointer" onclick="openNav()">&#9776;</span> -->
      <!-- <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn" [disabled]="true">

      </button> -->
      <!-- <img class="m-l-10 hospital_logo_Practice practice_logo" src="http://3.6.5.222:8010/1693151992318_HDRI_Sample_Scene_Balls_(JPEG-HDR).jpg" alt="Hospito Logo" /> -->
      <span class="m-l-5 practice_letter_logo practice_name_icon" *ngIf="isLogo"
        [ngStyle]="{'background': getRandomColor()}"><span class="_letter_name user_letters">{{getPracticeName(practiceName)}}</span></span>
      <img class="m-l-10 hospital_logo_Practice practice_logo" *ngIf="!isLogo" [src]="LogoUrl" alt="Practice Logo" />
      <h4 class="HeaderTitle">{{ practiceName  | titlecase }}<span class="topbar_subTitle">Practice</span></h4>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item">
          <div class="d-flex">
            <!-- <img
              class="rounded-circle header-profile-user"
              src="assets/images/users/avatar@2x.png"
              alt="Header Avatar"
            /> -->

            <!-- <h6 class="d-flex">
              {{
              currentUser?.firstname + " " + currentUser?.lastname | shortName:2
              }} 
              <span>{{
                currentUser?.usertype[0]?.name
                }}</span>
            </h6> -->

            <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
            <div class="col">
              <div ngbDropdown class="d-inline-block">
                <span class="logout_icon" id="dropdownBasic1" ngbDropdownToggle (click)="showDropdown()"><span
                    class="user_name_logo" [ngStyle]="{'background': getRandomColor()}"><span
                      class="_letter_name user_letters">{{currentUser?.firstname + "/" + currentUser?.lastname |
                      shortName:2}}</span></span>&nbsp;<span
                    class="_color font-14 font-weight-500">{{currentUser?.usertype[0]?.name}}</span> &nbsp;<i
                    class="fa-solid fa-caret-down"></i>
                </span>
                <div ngbDropdownMenu id="dropdown" aria-labelledby="dropdownBasic1">
                  <div class="card user_info_card" style="width: 24rem;">
                    <div class="card-body user_info_card_body">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 text-end">
                          <button type="button" class="btn-close text-end _color" aria-label="Close"
                            (click)="close($event)"></button>
                        </div>

                      </div>
                      <div class="clearfix">&nbsp;</div>
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                          <span class="user_name_logo" [ngStyle]="{'background': getRandomColor()}"><span
                              class="_letter_name user_letters">{{currentUser?.firstname + "/" + currentUser?.lastname |
                              shortName:2}}</span></span>
                        </div>
                        <div class="clearfix">&nbsp;</div>
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                          <h5 class="_color">Hi, {{currentUser?.firstname + " " + currentUser?.lastname }}</h5>
                          <div class="col-md-12 col-lg-12 col-sm-12 text-center">
                            <h5 class="card-title _color">{{currentUser.email}}</h5>
                          </div>
                          <p class="font-14 font-weight-500 _redcolor">{{currentUser.usertype[0].name}}</p>
                        </div>
                      </div>
                      <div class="row justify-content-md-center">
                        <div class="col-md-5 col-lg-5 col-sm-5 user_add_button bg_standard_color text-center">
                          <button type="button" (click)="settings()" class="btn user_info_button">Settings &nbsp;<i
                              class='bx bxs-cog' style="vertical-align:middle;font-weight: 600;"></i> </button>
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-5 user_signout_button bg_standard_color text-center">
                          <button type="button" class="btn white_color font_1rem user_info_button"
                            id="vertical-menu-btn" (click)="logout()" title="Sign out">
                            Logout &nbsp;&nbsp;<i class="fa fa-sign-out-alt fa-1x"></i>
                          </button>
                        </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </button>

      </div>

      <!-- <button type="button" class="btn btn-sm px-3 font-size-16 header-item logoutIcon" id="vertical-menu-btn"
        (click)="logout()" title="Logout">
        <i class="fa fa-sign-out-alt" style="font-size:0.9rem;"></i>
      </button> -->



    </div>
  </div>
</header>