export class User {
  _id: string;
  token: string;
}

export class UserDetails {
  _id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  createdon: Date;
  createdby: string;
  modifiedon: Date;
  modifiedby: string;
  usertype: UserType[];
  updatepassword?:boolean;
  firstName?:string;
  lastName?:string;
}

export class UserType {
  _id: string;
  name: string;
  createdon: Date;
  createdby: string;
  modifiedon: Date;
  modifiedby: string;
}

export class Login {
  email: string;
  password: string;
  applicationName: string;
}
export interface KCUserAttributes{
  type?: string;
  value?: string;
  temporary?: boolean
}
export interface KCUser {
  id: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  credentials?: [KCUserAttributes];
  groups: [string];
}



export const APP_CONSTANTS = {
  cookiekey:  'aaamb-client',
  cookieexpiry: '7',
  imgurl: 'http://3.6.5.222:8010/',
  USPS_ID: "05WAAAMB72833",
  USPS_REV: 1
};