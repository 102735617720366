<div id="layout-wrapper">

  <app-topBar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topBar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>


  <div class="main-content">
    <div class="page-content">
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

  </div>
</div>
