import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONSTANTS, Login, User, UserDetails } from "../models/auth.models";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUserData: BehaviorSubject<any>;
  public currentUser: Observable<User>;
  private userURL: string = environment.mainServiceAPI;
  public userSubject: BehaviorSubject<User>;

  constructor(private http: HttpClient, private cookie: CookieService) {
    let user = this.cookie.get("user")
    if(user){
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(user)
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserData = new BehaviorSubject<any>(
      JSON.parse(user)
    );
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject?.value;
  }

  public setCurrentUser(user: any) {
    this.currentUserSubject.next(user);
  }

  login(email: string, password: string) {
    let login: Login = {
      email: email,
      password: password,
      applicationName: "CT",
    };
    return this.http
      .post<any>(environment.mainServiceAPI + `/user/login`, login)
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user.data && user.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(user.data));
            this.currentUserSubject.next(user.data);
            this.setCurrentUser(user.data);
          }
          return user;
        })
      );
  }

  getUsersDetails(id: string) {
    return this.http
      .get<UserDetails>(environment.mainServiceAPI + `/user/` + id)
      .pipe(
        map((user) => {
          this.currentUserData?.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  // getting of the userId
  get currentUserId(): string {
    const userId = JSON.parse(this.cookie.get("user"))?.id;
    return userId;
  }

  // send reset link
  resetPassword(email: string, name: string): Observable<any> {
    return this.http.post(
      environment.mainServiceAPI + "/user/sendctresetlink/email",
      {
        email: email,
        name: name
      }
    );
  }

  EmailExists(email: string): Promise<any> {
      try {
          return lastValueFrom(
              this.http.post(
                  this.userURL + "/user/emailexists",
                  {email: email, name: ""}              )
          );
      } catch (error: any) {
          // console.log(JSON.stringify(error));
          throw new Error(error.message);
      }
  }

  signedInUserData(username: string) {
    const loginUser = {
      email: username,
    };
    return this.http
      .post<any>(this.userURL + `/user/signedindata`, loginUser)
      .pipe(
        map((user) => {
          this.cookie.delete(APP_CONSTANTS.cookiekey, "/");
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.cookie.set(
            APP_CONSTANTS.cookiekey,
            JSON.stringify(user),
            1,
            "/"
          );
          //localStorage.setItem('user', JSON.stringify(user));
          // this.userSubject.next(user);
          return user;
        })
      );
  }
}
