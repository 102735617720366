<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="page-title-box d-flex align-items-center">
          <div class="page-title-left">
            <h2 class="card-title">Credentialing Services</h2>
          </div>
          <span class="ml-5 m-top-5">  
            <button class="btn btn_popover_noborder me-2" placement="end"
              ngbPopover="To create/access the demographic file update of an entity/provider with payer(s) & its workflow status." popoverTitle="Prerequisite Info">
              <i class="fa fa-info-circle _info_color_icon"></i>
            </button>
          </span>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="card-sub-title text-gray-600 mb-4">Project Summary</h4>
          </div>
        </div>
        
  
        <div id="basic-example" role="application" class="wizard clearfix">
          <div class="steps clearfix">
            <ul role="tablist">

              <li role="tab" class="done" aria-disabled="true">
                <a id="basic-example-t-1" href="javascript: void(0);" aria-controls="basic-example-p-1"><span
                    class="number">01</span>
                  <p>Choose <b>Service</b></p>
                </a>
              </li>
              <li role="tab" class="done" aria-disabled="true">
                <a id="basic-example-t-2" href="javascript: void(0);" aria-controls="basic-example-p-2"><span
                    class="number">02</span>
                    <p  *ngIf="!isNPIType">Choose <b>Practice/Providers </b></p>
                    <p *ngIf="isNPIType">Choose <b>Practice </b></p>
                </a>
              </li>
              <li role="tab" class="done" aria-disabled="true" aria-selected="true">
                <a id="basic-example-t-3" href="javascript: void(0);" aria-controls="basic-example-p-3"><span
                    class="number">03</span>
                  <p>
                    Choose
                    <b>Credentialing Service</b>
                  </p>
                </a>
              </li>
              <li role="tab" class="done" aria-disabled="true">
                <a id="basic-example-t-4" href="javascript: void(0);" aria-controls="basic-example-p-5"><span
                    class="number">04</span>
                  <p>
                    Choose
                    <b>Enrollments/Payer(s)</b>
                  </p>
                </a>
              </li>
              <li role="tab" class="last current" aria-disabled="false">
                <a id="basic-example-t-5" href="javascript: void(0);" aria-controls="basic-example-p-5"><span
                    class="number">05</span>
                  <p>Review and Complete <b>Your Project</b></p>
                </a>
              </li>
            </ul>
          </div>
          <div class="content-con">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9">
                    <h4 class="h4 text-black ellipsis">
                      <span>Service Type:</span> Credentialing Services
                    </h4>
                  </div>
                  <div class="col-lg-3">
                    <a routerLink="/information/cs/speciality" class="custom-link">Modify Credentialing Services</a>
                  </div>
                </div>
                <div class="accordion">
                  <div class="card order_details_tabs" *ngFor="let src of data; let i = index" [ngClass]="{ expanded: hideRow[i] }" style=" border: 1px solid #0047453d !important;">
                    <div class="card-header" id="headingOne" (click)="changeValue(i)">
                      <div class="row">
                        <div class="col-lg-8">
                          <h5 class="mb-0">
                            {{ src.name }}
                          </h5>
                        </div>
                        <div class="col-lg-3">
                          <h5 class="mb-0">NPI: {{ src.npinumber }}</h5>
                        </div>
                        <div class="col-lg-1">
                          <div class="justify-content-end">
                            <a *ngIf="hideRow[i]">
                              <i class="bx bx-chevron-right"></i>
                            </a>
                            <a *ngIf="!hideRow[i]">
                              <i class="bx bx-chevron-down"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div [ngClass]="{ show: !hideRow[i] }" class="collapse">
                      <!--Gov State-->
                      <div class="row" *ngIf="getGovStateData(src._id).length > 0">
                        <div class="col-lg-12">
                          <div class="card orders_cards">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table class="table-responsive datatables table-services">
                                  <thead>
                                    <tr>
                                      <th colspan="2"class="W-50">GOV/STATE</th>
                                      <th colspan="">
                                        Services
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colspan="2" class="order-cnt">
                                        <div  [ngClass]="[isLast  ? 'ml-15' : 'border_bottom_styles_order']" class="_color"  *ngFor="let item of getGovStateData(src._id);index as i; last as isLast">
                                          <span class="m-l-15">{{ item.name }}</span>
                                        </div>
                                      </td>
                                      <td colspan="">
                                        <table class="table">
                                          <tbody>
                                            <tr *ngFor="let item of getServiceData(src._id) | keyvalue">
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length" scope="row">Add to
                                                Existing Practice</td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length" scope="row">Change
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length" scope="row">Update
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'terminate' && item?.value.length" scope="row">
                                                Terminate</td>
                                              <td class="td_Padding" *ngIf="item?.value.length">:</td>
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length"><span class="me-1"
                                                  *ngFor="let atep of item.value">
                                                  <b>{{ atep.name }}</b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length"> <span
                                                  *ngFor="let c of item.value"><b>{{ c.name }}</b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length"><span
                                                  *ngFor="let u of item.value"><b>{{ u.name }} </b></span></td>
                                              <td class="td_Padding"  *ngIf="item.key === 'terminate' && item?.value.length"><span
                                                  *ngFor="let t of item.value"><b>{{ t.name }}</b></span></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--EPO PPO-->
                      <div class="row" *ngIf="getPPOEPOData(src._id).length > 0">
                        <div class="col-lg-12">
                          <div class="card orders_cards">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table class="table-responsive datatables table-services">
                                  <thead>
                                    <tr>
                                      <th colspan="2" class="W-50">COMMERCIALS/MANAGED CARE</th>
                                      <th colspan="">
                                        Services
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colspan="2" class="order-cnt">
                                        <div [ngClass]="[isLast  ? 'ml-15' : 'border_bottom_styles_order']" class="_color" *ngFor="let item of getPPOEPOData(src._id);index as i; last as isLast">
                                          <span class="m-l-15">{{ item.name }}</span>
                                        </div>
                                      </td>
                                      <td colspan="">
                                        <table class="table">
                                          <tbody>
                                            <tr *ngFor="let item of getServiceData(src._id) | keyvalue">
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length" scope="row">Add to
                                                Existing Practice</td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length" scope="row">Change
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length" scope="row">Update
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'terminate' && item?.value.length" scope="row">
                                                Terminate</td>
                                              <td class="td_Padding" *ngIf="item?.value.length">:</td>
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length"><span class="me-1"
                                                  *ngFor="let atep of item.value"><b> {{ atep.name }} {{atep?.otherServiceText}}</b></span> </td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length">
                                              
                                                <span
                                                  *ngFor="let c of item.value"><b>{{ c.name }} {{c?.otherServiceText}}</b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length"><span
                                                  *ngFor="let u of item.value"><b>{{ u.name }} {{u?.otherServiceText}} </b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'terminate' && item?.value.length"><span
                                                  *ngFor="let t of item.value"><b> {{ t.name }} {{t?.otherServiceText}}</b></span></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--HMO-->
                      <div class="row" *ngIf="getHMOData(src._id)?.length > 0">
                        <div class="col-lg-12">
                          <div class="card orders_cards">
                            <div class="card-body">
                              <div class="table-responsive">
                                <table class="table-responsive datatables table-services">
                                  <thead>
                                    <tr>
                                      <th colspan="2" style="width: 40%;">HMO</th>
                                      <th colspan="">
                                        Services
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td colspan="2" class="order-cnt">
                                        <div class="_color border_bottom_styles_order" *ngFor="let item of getHMOData(src._id)">
                                            <span class="m-l-15">{{ item.name }}</span>
                                        </div>
                                      </td>
                                      <td colspan="">
                                        <table class="table">
                                          <tbody>
                                            <tr *ngFor="let item of getServiceData(src._id) | keyvalue">
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length" scope="row">Add to
                                                Existing Practice</td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length" scope="row">Change
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length" scope="row">Update
                                              </td>
                                              <td class="td_Padding" *ngIf="item.key === 'terminate' && item?.value.length" scope="row">
                                                Terminate</td>
                                              <td class="td_Padding" *ngIf="item?.value.length">:</td>
                                              <td class="td_Padding" *ngIf="item.key === 'atep' && item?.value.length"><span
                                                  *ngFor="let atep of item.value"><b>{{ atep.name }}</b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'change' && item?.value.length"> <span
                                                  *ngFor="let c of item.value"><b>{{ c.name }}</b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'update' && item?.value.length"><span
                                                  *ngFor="let u of item.value"><b>{{ u.name }} </b></span></td>
                                              <td class="td_Padding" *ngIf="item.key === 'terminate' && item?.value.length"><span
                                                  *ngFor="let t of item.value"><b>{{ t.name }}</b></span></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-5 d-flex justify-content-between">
                <a class="btn custom-btn" routerLink="/information/cs/enrollments" role="menuitem">Previous</a> <a class="btn custom-btn-2" (click)="createOrder()">Create Project</a>
              </div>
            </div>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
  </div>
</div>