import { Directive, OnInit, OnDestroy, ElementRef, Input, HostListener } from "@angular/core";
import { NgControl } from '@angular/forms';

@Directive( {
  selector : 'trim'
})
export class FormControlTrimDirective implements OnInit, OnDestroy {

  @Input() debounce: number = 300;

  isAlive = true;

  // https://stackoverflow.com/a/41175292
  constructor(private el: ElementRef, private ngControl : NgControl) { 
  }

  ngOnInit() {
    if (this.ngControl.control) {
      this.ngControl.control.valueChanges
      .subscribe((changes: string) => {
        if (changes == null) {
          return;
        }

        const trimmed = changes.trim();
        if (trimmed !== changes) {
          this.ngControl.control.setValue(trimmed);
        }
      });
    } else {
      console.error('FormControlTrimDirective: FormControl must exist!');
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}