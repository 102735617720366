import { Component, OnInit } from "@angular/core";
import { ProviderService } from "src/app/shared/services/provider.service";
import { PayersService } from "src/app/shared/services/payers.service";
import {
  Group,
  Provider,
  ProviderEnrollmentData,
} from "src/app/shared/models/provider.model";
import { Data } from "src/app/shared/models/data.model";
import { SpecialityService } from "src/app/shared/services/speciality.service";
import { ProviderSpecialityData } from "src/app/shared/models/speciality.model";
import { Payers, PayersType } from "src/app/shared/models/payers.model";
import { OrderService } from "src/app/shared/services/order.service";
import { CreateOrderInput } from "src/app/shared/models/order.model";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { CredentialSpecialtyService } from "src/app/shared/services/credentialspecialty.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/shared/services/user.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-information-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"],
})
export class CredentialOrderComponent implements OnInit {
  providerData: Provider[] = [];
  payerTypes: PayersType[] = [];
  public payerSubType: any = [];
  specialityData: any[] = [];
  specialityType: any = [];
  groupData: Group[] = [];
  hideRow: boolean[] = [];
  payers: Payers[] = [];
  data: Data[] = [];
  govState: Data[];
  ppoEpo: Data[];
  hmo: Data[];
  providerEnrollment: ProviderEnrollmentData;
  providerSpecialty: ProviderSpecialityData;
  public savedCsServiceData: any;
  PracticeUserInfo: any;
  isNPIType: boolean;
  LoginUserInfo: any;
  PracticeID: any;

  constructor(
    private providerService: ProviderService,
    private specialityService: SpecialityService,
    private payersService: PayersService,
    private orderService: OrderService,
    private router: Router,
    private credentialSpecialtyService: CredentialSpecialtyService,
    private loaderService: LoaderService,
    private tosterService: ToastrService,
    private userService: UserService,
    private cookie: CookieService,

  ) {
    //get the data from cookies and stored in the below Array obj
    const UserInfo = JSON.parse(this.cookie.get("userDetails"));   // Login user Info
    const PracticeUserInfo = JSON.parse(this.cookie.get("user"));     // Practice User details inc npi
    if (UserInfo) { this.LoginUserInfo = UserInfo; }
    if (PracticeUserInfo) {
      this.PracticeUserInfo = PracticeUserInfo;
      this.PracticeID = this.PracticeUserInfo.practiceData._id
    }   // Practice ID for Global
    if (this.PracticeUserInfo.practiceData.npitype[0].name == "Individual") {
      this.isNPIType = true;
    }
  }

  async ngOnInit() {
    const providerIds = localStorage.getItem("selectedProviderIds");
    const groupIds = localStorage.getItem("selectedGroupIds");
    await this._fetchData(providerIds, groupIds);
    this.getSubPayers();
  }

  private getSubPayers() {
    this.loaderService.isLoading.next(true);
    this.payersService.getAllPayerSubtype().subscribe({
      next: (res) => {
        this.payerSubType = res;
      },
      error: (error) => {
        this.tosterService.error(JSON.stringify(error));
      },
      complete: () => {
        this.loaderService.isLoading.next(false);
      },
    });
  }

  changeValue(index) {
    for (let i = 0; i < this.providerData.length; i++) {
      if (i !== index) this.hideRow[i] = true;
    }
    this.hideRow[index] = !this.hideRow[index];
  }

  async _fetchData(providerIds, groupIds) {
    const groupData = await firstValueFrom(this.providerService.getGroups());
    this.groupData = groupData;
    this.groupData = this.groupData?.filter((a) => groupIds?.includes(a._id));

    for (
      let i = 0;
      i < this.providerData.length + this.groupData?.length;
      i++
    ) {
      if (i === 0) this.hideRow[i] = false;
      else this.hideRow.push(true);
    }

    for (let i = 0; i < this.groupData?.length; i++) {
      const data: Data = {
        _id: this.groupData[i]._id,
        name: this.groupData[i].name,
        npinumber: this.groupData[i].npinumber,
      };
      this.data.push(data);
    }
    const providerData = await firstValueFrom(
      this.providerService.getProviders()
    );
    this.providerData = providerData;

    this.providerData = this.providerData.filter((a) =>
      providerIds?.includes(a._id)
    );

    for (
      let i = 0;
      i < this.providerData?.length + this.groupData?.length;
      i++
    ) {
      if (i === 0) this.hideRow[i] = false;
      else this.hideRow.push(true);
    }

    for (let i = 0; i < this.providerData?.length; i++) {
      const data: Data = {
        _id: this.providerData[i]?._id,
        name:
          this.providerData[i]?.firstname +
          " " +
          this.providerData[i]?.lastname,
        npinumber: this.providerData[i].npinumber,
      };
      this.data.push(data);
    }

    this.providerService.getProviderEnrollment().subscribe((res) => {
      this.providerEnrollment = res?.data;
      this.payersService.getPayerTypes().subscribe((res) => {
        this.payerTypes = res;
        this.payersService.getPayers().subscribe((res) => {
          this.payers = res;
        });
      });
    });
    const res = await firstValueFrom(
      this.credentialSpecialtyService.getServiceType()
    );
    this.specialityType = res?.data;

    const s = await firstValueFrom(
      this.credentialSpecialtyService.getCredentialService()
    );

    this.specialityData = s?.data;
  }

  getGovStateData(id) {
    this.govState = [];
    const govStatePayerType = this.payerTypes?.find(
      (a) => a.name === "GOV/STATE"
    );

    if (govStatePayerType) {
      const selectedEnrollment = JSON.parse(
        localStorage.getItem("allSelectedCsEnrollment")
      )[id];
      if (selectedEnrollment) {
        let allServices = [];

        allServices = [...allServices, ...selectedEnrollment];

        for (let e of allServices) {
          const payer = this.payers.find((p) => p._id === e._id);
          if (payer && payer.payertype === govStatePayerType._id) {
            const exists = this.govState.find((a) => a._id === payer._id);
            if (!exists) {
              this.govState.push(payer);
            }
          }
        }
      }
    }
    return this.govState;
  }

  getPPOEPOData(id) {
    this.ppoEpo = [];
    const ppoEpoPayerType = this.payerTypes?.filter(
      (a) => a.name === "Commercials/Managed Care"
    );

    if (ppoEpoPayerType) {
      const selectedEnrollment = JSON.parse(
        localStorage.getItem("allSelectedCsEnrollment")
      )[id];
      if (selectedEnrollment) {
        let allServices = [];

        allServices = [...allServices, ...selectedEnrollment];

        const epoPpoSubType = this.payerSubType.find(
          (res) => res.name == "EPO/PPO"
        );
        for (let e of allServices) {
          const payer = this.payers.find((p) => p._id === e._id);
          if (
            payer &&
            payer.payertype === ppoEpoPayerType[0]?._id &&
            payer?.payersubtype === epoPpoSubType?._id
          ) {
            const exists = this.ppoEpo.find((a) => a._id === payer._id);
            if (!exists) {
              this.ppoEpo.push(payer);
            }
          }
        }
      }
    }
    return this.ppoEpo;
  }

  getHMOData(id) {
    if (0 < this.payers?.length) {
      this.hmo = [];
      const hmoPayerType = this.payerTypes?.find(
        (a) => a.name === "Commercials/Managed Care"
      );
      if (hmoPayerType) {
        const selectedEnrollment = JSON.parse(
          localStorage.getItem("allSelectedCsEnrollment")
        )[id];
        if (selectedEnrollment) {
          let allServices = [];

          allServices = [...allServices, ...selectedEnrollment];
          const hmoSubType = this.payerSubType.find((res) => res.name == "HMO");
          for (let e of allServices) {
            const payer = this.payers.find((p) => p._id === e._id);
            if (
              payer &&
              payer.payertype === hmoPayerType?._id &&
              payer?.payersubtype == hmoSubType?._id
            ) {
              const exists = this.hmo.find((a) => a._id === payer._id);
              if (!exists) {
                this.hmo.push(payer);
              }
            }
          }
        }
      }
      return this.hmo;
    }
  }

  getServiceData(id): Object {
    const services = { atep: [], change: [], update: [], terminate: [] };
    const selectedSpecialities = JSON.parse(
      localStorage.getItem("allSelectedCsServices")
    )[id];
    if (selectedSpecialities && selectedSpecialities?.length > 0) {
      for (let s of selectedSpecialities) {
        const curr = this.specialityData.find((a) => a._id === s?._id);
        const cred = this.specialityType.find(
          (ss) => ss._id === curr?.credentialtype
        );
        if(s.name === "Others") {
          curr.otherServiceText = s.otherText;
        }
        
        switch (cred?.name?.replace(/\s+/g, "")?.toLowerCase()) {
          case "terminate":
            services.terminate.push(curr);
            break;
          case "addtoexistingpractice":
            services.atep.push(curr);
            break;
          case "change":
            services.change.push(curr);
            break;
          case "update":
            services.update.push(curr);
            break;
        }
      }
    }
    return services;
  }

  // saving of the provider ids.
  async saveServices(providerIds: any, groupIds: any) {
    let selectedCsServices = JSON.parse(
      localStorage.getItem("allSelectedCsServices")
    );
    let selectedEnrollment = JSON.parse(
      localStorage.getItem("allSelectedCsEnrollment")
    );

    const practiceid = JSON.parse(this.cookie.get("user"))
      ?.practiceData?._id;
    let practiceservices = [];
    // console.log(selectedEnrollment);
    // practices data.
    if (groupIds && groupIds?.length > 0) {
      practiceservices = groupIds.map((groupid) => {
        return {
          practiceid: groupid,
          services: selectedCsServices[groupid].map((csService) => {
            return {
              service: csService._id,
              
              payers: selectedEnrollment[groupid]
                ?.map((e) => {
                  if (e.serviceId === csService._id) {
                    return e._id;
                  }
                })
                .filter((res) => res != undefined),
                
            };
          }),
        };
      });
    }

    // providers data.
    let providerservices = providerIds?.map((providerId) => {
      return {
        providerid: providerId,
        services: selectedCsServices[providerId]?.map((csService) => {
          return {
            service: csService._id,
            others: csService.otherText,
            payers: selectedEnrollment[providerId]
              ?.map((e) => {
                if (e.serviceId === csService._id) {
                  return e._id;
                }
              })
              .filter((res) => res != undefined),
              //others
          };
        }),
      };
    });
    // let practice Credentials service data.
    let practiceCerendentialServiceData: any = {
      practiceid: practiceid,
      practiceservices: practiceservices,
      providerservices: providerservices,
      createdby: JSON.parse(this.cookie.get("user"))?.id,
      modifiedby: JSON.parse(this.cookie.get("user"))?.id,
      createdon: new Date(),
      modifiedon: new Date(),
    };

    // saving of the credential service
    this.credentialSpecialtyService
      .saveCredentialService(practiceCerendentialServiceData)
      .subscribe({
        next: (res) => {
          this.savedCsServiceData = res.data;
        },
        error: (error) => {
          this.tosterService.error(String(error));
        },
        complete: () => {
          this.postOrders(providerIds, groupIds);
        },
      });
  }

  private postOrders(providerIds, groupIds) {
    const order: CreateOrderInput = {
      providerid: providerIds,
      practiceid:
        groupIds?.length > 0
          ? groupIds[0]
          : JSON.parse(this.cookie.get("user"))?.practiceData?._id,
      type: "CS",
      // others:'text hear',
      projsequence: this.savedCsServiceData.projsequence,
    };
    // console.log(order)
    this.orderService.createOrders(order).subscribe((res) => {
      this.notifyProvider(order)
      localStorage.removeItem("selectedGroupIds");
      localStorage.removeItem("selectedProviderIds");
      localStorage.removeItem("allSelectedCsEnrollment");
      localStorage.removeItem("saveProviderEnrollmentInput");
      localStorage.removeItem("saveProviderSpeciality");
      localStorage.removeItem("allSelectedCsServices");
      localStorage.removeItem("allSelectedItems");
      localStorage.removeItem("allSelectedCsServices");
      localStorage.removeItem("saveProviderCsServices");
      localStorage.setItem("order", JSON.stringify(res.data));
      this.router.navigate(["/information/success"]);
    });
  }

  async createOrder() {
    const providerIds = JSON.parse(localStorage.getItem("selectedProviderIds"));
    const groupIds = JSON.parse(localStorage.getItem("selectedGroupIds"));
    await this.saveServices(providerIds, groupIds);
  }

  // notify proivder
  private notifyProvider(orders: any) {
    this.userService.notifyProjectUsers(orders).subscribe({
      next: (res) => {
        // console.log(res);
      },
      error: (error) => {
        // console.log(error);
      },
    });
  }
}
