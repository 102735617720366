<div class="container-fluid p-0 body_background">
    <div class="row g-0">
        <div class="col-xl-12">
            <div class="auth-full-bg">
                <div class="w-100 h-100">
                    <!--Header-->
                    <div class="header">
                        <div class="logo">
                            Credentials Today
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                            <h1 class="_color">Privacy Policy</h1>
                        </div>
                    </div>
                    <div class="container">
                        <div class="section-title mt-5">
                            <h4>Welcome to<span> Credential Today </span>Privacy!</h4>
                            <h5 class="sub-title">We take privacy very seriously.</h5>
                        </div>
                        <div class="container testi-con mt-4" style="text-align: justify;">
                            <div class="row">
                                <p>
                                    Credential Today Privacy follows fair information practices like transparency and only sharing information with people who need to know. To protect your privacy, we’ll tell you before we collect any personal information we need to run your credentialing applications, and only use it for that purpose.
                                </p>
                                <p>
                                    Credential Today is responsible for privacy protections following federal laws, regulations, and policies for keeping personal and business information safe.
                                </p>
                                <p>
                                    Protecting your privacy is very important to us. This privacy policy describes what information we collect, why we collect it, and what we do with it.  This privacy notice covers: <a href="#" target="_blank">www.credentialtoday.com</a> and all subsidiaries. These sites are referred to as “Credential Today” throughout the rest of this notice and are maintained and operated by Credential Today management.
                                
                                   
                                </p>
                                <p>
                                    Credential Today doesn’t collect name, contact information, or other similar information through these websites unless you choose to provide it. We do collect other, limited, non-personally identifiable information automatically from visitors who read, browse, and/or download information from our website. We do this so we can understand how the website is being used and how we can make it more helpful. 
                                </p>
                                <p>
                                    Personally identifiable information (PII), defined by the Office of Management and Budget (OMB), refers to information that can be used to distinguish or trace an individual's identity, like their name, biometric records, etc. alone, or when combined with other personal or identifying information which is linked or linkable to a specific individual, like date and place of birth, SS, mother’s maiden name, etc. PII data is considered protected health information (PHI) under the Health Insurance Portability and Accountability Act (1996) (HIPAA) regulations.
                                </p>
                                <p>
                                    <b
                                        >We don’t sell any information you provide when you
                                        visit Credential Today.
                                    </b>
                                </p>
                                <p>
                                    Credential Today is is committed to helping to reduce healthcare administrative burdens, which includes protecting your privacy. The information we collect through our websites and services helps us to provide you with information specific to your needs and interests. This privacy policy (the “Privacy Policy”) governs your access to and use of all current and any future Credential Today websites and the services offered on those websites.
                                </p>
                                
                                
                                <p>
                                    For our various services, additional terms may apply. Additional service-specific terms may be accessed through the Terms of Service.
                                </p>
                                <p>
                                    Additionally, this Privacy Policy is to be read along with any separate service agreement(s) that you may have in place with Credential Today (Credential Today). If there is a conflict between that agreement and this Privacy Policy, the terms of the service agreement will control.
                                </p>
                                <p>
                                    By accessing any of our sites or services, you agree to be bound by this privacy policy. If you do not agree to the terms of this privacy policy, please do not use our sites or services. Each time you use our sites or services, the current version of the privacy policy will apply. This privacy policy is applicable to all visitors, registered users, and all other users of our sites or services.
                                </p>
                                <p>
                                    The provisions contained herein supersede all previous notices or statements regarding our privacy practices with respect to the sites and services
                                </p>
                                <h3 class="privacy">2. HOW DOES Credential Today GATHER AND USE INFORMATION?</h3>
                                <p>
                                    “Credential Today Content” means including, without limitation, characters, data, images, photographs, animations, video, audio, music, text, and applets on the Sites, and all other material and information provided by Credential Today or its third-party providers that you see on the sites and/or through the services. Any content that you provide to Credential Today regarding feedback or ideas on the services shall become Credential Today Content.
                                </p>
                                <p>
                                    “Content” shall mean Credential Today Content, Third Party Data, and
                                    User Submitted Data collectively.
                                </p>
                                <p>
                                    “Personal Information” includes any information that
                                    identifies you, would enable someone to contact you, or that
                                    describes your relationship with Credential Today.
                                </p>
                                <p>
                                    “Third Party Data” shall mean data that Credential Today obtains from
                                    third party sources.
                                </p>
                                <p>
                                    “User Submitted/Access Data” shall mean any information that
                                    you submit to Credential Today through the Sites or Services such as
                                    provider contact information, payment information or
                                    certifications, user and passwords for third party entities
                                    (PECOS, CAQH, NPPES) and includes Personal Information that
                                    you submit directly to Credential Today (SS, DOB, Address).
                                </p>
                                <p>
                                    The use of User Submitted/Access Data is subject to this
                                    Privacy Policy and the Terms of Use (including any
                                    Site-specific Terms associated with a particular Service) in
                                    effect at the time we use such information. This section
                                    provides details about how Credential Today gathers and uses User
                                    Submitted/Access Data.
                                </p>
                                <p>
                                    <b>(a) Cookies.</b> Cookies are pieces of data assigned by a web server that uniquely identify the browser on your computer. Credential Today installs cookies when a user arrives at any of our Sites or uses our Services. Cookies enable our systems to gather information about your navigational patterns through the sites and services. Viewers have the option to accept or disable cookies at any time through their browsers. Visit the “help” portion of your toolbar for more information. If you block or refuse to accept cookies or delete cookies between visits to any of our Sites, you may not be able to access or use certain features or functions of our sites or services.
                                </p>
                                <p>
                                    <b>(b) User Analytics.</b> We may collect general statistics
                                    to track user trends on our Sites and Services. These
                                    statistics may include hits to our server, the types of
                                    browsers used to access our Sites, page views, and
                                    navigational patterns. The information that is collected in
                                    this manner does NOT provide us with any Personal
                                    Information about our users. It helps us track “where” our
                                    users are coming from. This allows us to pinpoint high
                                    traffic areas and determine the most effective ways to
                                    communicate with our users. We may use this statistical
                                    information for research and commercial purposes and to
                                    develop improvements to our Sites and Services.
                                </p>
                                <p>
                                    One of the tools we use to collect and track user statistics
                                    and trends related to our Sites and Services is Google
                                    Analytics. Information about how Google Analytics operates
                                    and uses data from our Sites and Services is available at:
                                    <a href="http://www.google.com/policies/privacy/partners" target="_blank"
                                        >https://policies.google.com/technologies/partner-sites</a
                                    >
                                    . As part of our use of Google Analytics we will install
                                    cookies as set forth above in Section 2(a).
                                </p>
                                <p>
                                    <b>(c) Information Users Provide to Credential Today.</b> We collect
                                    and store User Submitted Data when you register to use our
                                    Sites or Services, utilize the Services on our Sites, ask a
                                    question, complete a form, conduct a transaction, or send us
                                    an email. To use our Services, you will be required to
                                    submit certain User Submitted Data, including Personal
                                    Information. If you do not provide the requested
                                    information, you may not be able to access or use some of
                                    the features and functions of our Sites and we may not be
                                    able to provide you with our Services. For administrative
                                    and security purposes, such as to prevent the introduction
                                    of viruses and hackers into THE Credential Today site, we may collect
                                    and retain information, such as IP addresses.
                                </p>
                                <p>
                                    We may use your User Submitted Data internally, among other
                                    things, to communicate with you, to provide information or
                                    Services to you, to contact you directly about Services that
                                    you have purchased, and for our research or business
                                    purposes
                                </p>
                                <p>
                                    We may also combine your User Submitted/Access Data with
                                    Third Party Data to provide our Services.
                                </p>
                                <p>
                                    Users who subscribe to certain of our Services will be
                                    required to provide certain User Submitted Data, User Access
                                    Data, including Personal Information that will be shared
                                    with other Users of the Services. The types of information
                                    collected through such Services include:
                                </p>
                                <div>
                                    <ul>
                                        <li>
                                            Provider practice information including demographic
                                            and credentialing data.
                                        </li>
                                        <li>
                                            Provider practice routing information for payment
                                            and remittance advices.
                                        </li>
                                        <li>
                                            Provider user information for other sites, including
                                            but not limited to PECOS and CAQH.
                                        </li>
                                    </ul>
                                </div>
                                <h3 class="privacy">
                                    3. WHAT SECURITY PROCEDURES DOES Credential Today USE TO PROTECT
                                    PERSONAL INFORMATION?
                                </h3>
                                <p>
                                    Credential Today uses physical, electronic, and administrative
                                    safeguards to assist us in preventing unauthorized access,
                                    maintaining data accuracy, and correctly using User
                                    Submitted Data. Information that is sent to us through our
                                    Sites and Services is secured by encryption. Because email
                                    transmissions are not secure, we encourage you to use
                                    encryption when sending us any User Submitted/Access Data by
                                    email, or to use another means of transmitting the
                                    information to us.
                                </p>
                                <p>
                                    Access to User Submitted Data through our Sites and Services
                                    is limited to registered persons who are required to use
                                    unique passwords to obtain access. We contractually prohibit
                                    these persons from using your User Submitted/Access Data
                                    other than for the purpose of using our Services (unless
                                    otherwise authorized by you).
                                </p>
                                <p>
                                    Credential Today will retain User Submitted/Access Data for as long as
                                    your account is active or as needed to provide you with our
                                    Service. If you wish to cancel your account or request that
                                    we no longer use your information to provide our Services,
                                    please contact us at credentialtoday@aaamb.com We will retain and use
                                    User Submitted Data as necessary to comply with our legal
                                    obligations, resolve disputes, and enforce our agreements.
                                </p>
                                <p>
                                    If you choose our services you agree to give us PII in an
                                    email, request for information, paper or electronic form,
                                    questionnaire, survey, etc., we’ll use the information you
                                    give us only to respond to your service requests or to
                                    fulfill the stated purpose of the communication.
                                </p>
                                <p>
                                    If we need to contact you, we’ll save your personal
                                    information in a record system designed to retrieve
                                    information about you by personal identifier (name, personal
                                    email address, home mailing address, personal or mobile
                                    phone number, etc.) and keep the information you give us
                                    safe according to the
                                    <a target="_blank"
                                        href="https://www.gpo.gov/fdsys/pkg/USCODE-2012-title5/pdf/USCODE-2012-title5-partI-chap5-subchapII-sec552a.pdf"
                                        >Privacy Act of 1974</a>, as amended (<a target="_blank"
                                        href="https://www.gpo.gov/fdsys/granule/USCODE-2010-title5/USCODE-2010-title5-partI-chap5-subchapII-sec552a/content-detail.html"
                                    >5 U.S.C. Section 552a</a>).
                                </p>
                                <p>If we have a record system to retrieve information about you so we can carry out our mission, a Privacy Act Notification Statement should be prominently displayed out in the open on the public-facing website or form asking you for PII. The statement has to address these 5 criteria:</p>
                                <div>
                                    <ol>
                                        <li>The legal authorization we have to collect information about you.</li>
                                        <li>Why we’re collecting information.</li>
                                        <li>Routine ways we disclose information outside of our websites.</li>
                                        <li>Whether or not you legally have to give us the information we’re asking for</li>
                                        <li>What happens if you choose to not us the information, we’re asking for</li>
                                    </ol>
                                </div>
                                <p>
                                    For more information about CMS.gov’s privacy policy, email
                                    <a href="mailto:Privacy@cms.hhs.gov">Privacy@cms.hhs.gov</a>.
                                </p>
                                <p>Third-party services are web-based technologies that aren’t exclusively operated or controlled by a government entity, or that involve significant participation of a non government entity. These services may be separate websites or may be applications embedded within our websites. The list of third-party services includes links to relevant third-party privacy policies.</p>
                                <h3 class="privacy">4. WILL Credential Today DISCLOSE THE USER SUBMITTED DATA WE COLLECT TO THIRD PARTIES?</h3>
                                <p>Credential Today may share User Submitted Data, including Personal Information contained within User Submitted Data, as described below.</p>
                                <p><b>(a) Affiliated Businesses We Control.</b>  We may share User Submitted Data with the affiliated businesses we control to make sure that your experience with us is as seamless as possible and that you have the full benefit of our Services. The use of User Submitted Data by such controlled affiliates remains subject to this Privacy Policy.</p>
                                <p><b>(b) Agents.</b>  We employ other companies and individuals to perform functions on our behalf. Some examples of such functions include operating and hosting our service offerings, supporting our information technology infrastructure, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, processing payments, and providing customer service. We share User Submitted Data with these agents as we deem necessary for such third parties to perform their functions. We may also provide aggregate statistics about our customers, traffic patterns and related site information to reputable third party vendors, but these statistics do not include Personal Information.</p>
                                <p><b>(c) Unaffiliated Third Parties.</b>  Credential Today may from time to time disclose User Submitted Data to third parties as set forth in Section 4(h) below, and in connection with initiatives or activities consistent with our mission to simplify healthcare administration.</p>
                                <p><b>(d) Protection of Credential Today and Others.</b>  We may disclose User Submitted Data collected from you to (a) comply with the law, applicable regulations, governmental and quasi-governmental requests, orders or subpoenas directed to us or our affiliates; (b) protect and defend our rights or property or the rights or property of other users of our Sites and Services including the enforcement of our Terms of Use or other agreements; (c) respond to an emergency or protect the personal safety of users of our Sites or Services, or the public; or (d) prevent a crime or protect national security (including exchanging information with other companies and organizations for fraud protection).</p>
                                <p><b>(e) Change of Control.</b>  In the event that a third party acquires all or a substantial portion of Credential Today’s business, whether such acquisition is by way of merger, liquidation, dissolution, consolidation or sale of all or a substantial portion of our business, trade or assets, informational Content (including User Submitted Data) will be transferred to the acquiring third party. By using our Site or Services, you consent to the sale and transfer of your User Submitted Data as described in this paragraph.</p>
                                <p><b>(f) Public Areas of our Site or Services.</b>  If you provide any User Submitted Data to Credential Today for posting in areas of our Sites or through our Services that may be publicly viewed (e.g., blogs or messaging features to the extent available), your information will be available to third parties who use our Sites or Services. Please be cautious when using public areas of our Sites or Services.</p>
                                <p><b>(g) Authorized by User.</b>  We may disclose User Submitted Data we collect from you if you request or authorize it.</p>
                                <p><b>(h) As Part of the Services.</b> Credential Today may disclose User Submitted Data, which you provide as part of your membership in the Services to Service Recipients and other Credential Today customers in accordance with any Terms of Service relating to each applicable Service. Access to such User Submitted Data through our Services is limited to registered users who are bound by agreements governing their use of Content (subject to any agreement you may have directly with Service Recipients relating to their use of your Personal Information).</p>
                                <p><b>(i) Privacy Policy.</b>  User Submitted Data may also be disclosed as otherwise described in this Privacy Policy.</p>
                                <p>(j) Access Date, including your personal user information for sites like PECOS and CAQH will not be shared or disclosed with Third Parties and agents.</p>
                                <h3 class="privacy">5. EXTERNAL LINKS</h3>
                                <p>Other companies or organizations may have links to their websites or services through our Sites or Services. We are not responsible for how these other companies or organizations collect, use, disclose, or secure the information that you provide them. If you choose to access a third party website or service linked to one of our Sites or Services, you do so at your own risk and subject to any terms of service or privacy policy (if any) associated with such third party websites.</p>
                                <h3 class="privacy">6. COLLECTION OF INFORMATION FROM CHILDREN</h3>
                                <p>Credential Today does not direct any of our content specifically to children. We do not knowingly collect or use Personal Information from individual under eighteen (18) without obtaining verifiable consent from their parents or a guardian. If we learn that a user is under 18 years of age, we will promptly delete any Personal Information we have collected about that user. If you are under the age of 18, please do not send us any information about yourself.</p>
                                <h3 class="privacy">7. DO NOT TRACK</h3>
                                <p>Credential Today does not respond to web browser “do not track” signals. As such, your navigation of our Sites and use of the Service may be tracked as part of the gathering of the general User Analytics collected by Credential Today. If you arrive at our Sites by way of a link from a third party site that does respond to “do not track” requests, the recognition of any “do not track” request you have initiated will end as soon as you reach our Sites.</p>
                                <h3 class="privacy">8. ACCESSING YOUR INFORMATION</h3>
                                <p>On some of our Sites or to use our Services, you may be required to register in order to access your information. You may be required, under certain agreements you may have with health plans, hospitals or other third parties, to ensure that your information with Credential Today is up-to-date and accurate. You may view and edit your User Submitted Data by accessing the Site and supplying your username and password or other required authentication. Your username and password or other authentication allows access to your information so you are responsible for the disclosure of your information to anyone using your username and password. Please protect these security devices and notify us immediately if they have been lost, stolen or misappropriated.</p>
                                <h3 class="privacy">9. UPDATING OUR POLICY</h3>
                                <p>Credential Today may at our sole discretion revise this Privacy Policy at any time. If any material changes are made, we will notify you by displaying a prominent announcement on our Sites prior to the new Privacy Policy taking effect. Depending on the Service, we may display notice of a change for a reasonable period. If you continue to use our Sites and Services following the posting of changes to this Privacy Policy, you will be deemed to have accepted the changes. Any revised privacy policies posted by Credential Today will supersede all previous notices or statements regarding the Sites or Services. </p>
                                <h3 class="privacy">10. OPT-OUT / INFORMATION CORRECTION OR REMOVAL</h3>
                                <p>If any of the information that we have about you is incorrect or you wish to have information (including Personal Information) removed from our records, please contact:
                                  <br/>  <b>Credential Today Help Desk: credentialtoday@aaamb.com</b>
                                </p>
                                <p>Additionally, if you prefer not to receive marketing messages from us, please let us know by clicking on the unsubscribe link within any marketing messages that you receive or by sending a message to us at credentialtoday@aaamb.com. This election will not affect your receipt of messages from us associated with the Services.</p>
                                <h3 class="privacy">11. INTERNATIONAL USERS</h3>
                                <p>Credential Today is headquartered in the United States of America. Personal Information that you provide may be accessed by Credential Today or transferred to Credential Today in the United States or to our affiliates, partners, merchants, or service providers elsewhere in the world in accordance with this Privacy Policy. By visiting our Sites or using our Services, you consent to this transfer. You explicitly acknowledge and consent to the fact that Personal Information will be stored and processed in the United States and will be subject to the laws of the United States, including the ability of governments, courts or law enforcement or regulatory agencies of the United States to obtain disclosure of your Personal Information.</p>
                                <h3 class="privacy">12. CERTAIN CALIFORNIA RIGHTS</h3>
                                <p>If you are a California resident, California Civil Code Section 1798.83 requires select businesses to disclose policies relating to the sharing of certain categories of your Personal Information with third parties. If you reside in California and have provided your Personal Information to Credential Today, you may request information about our disclosures of certain categories of Personal Information to third parties for direct marketing purposes. Such requests must be submitted to us at by email at Credential Today.com</p>
                                <p>Within thirty (30) days of receiving such a request, we will provide a list of the categories of Personal Information disclosed to third parties for third-party direct marketing purposes during the immediately preceding calendar year, along with the names and addresses of those third parties. This request may be made no more than once per calendar year. Credential Today reserves the right not to respond to requests submitted other than to the address specified in this Section.</p>
                                <h3 class="privacy">13. CONTACTING US</h3>
                                <p>The Sites and Services are owned and operated by Credential Today.  If you have questions or suggestions regarding this Privacy Policy or our privacy practices please send an email message to credentialtoday@aaamb.com. </p>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
        <div class="row  m-t-20">
            <div class="col-md-6 col-sm-12 col-lg-6">
                <p class="copy_rights_styles">Copyright ©2024 Credential Today LLC. All rights reserved</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-6 text-end">
                <div class="m-r-3p">
                    <small><a routerLink="/terms-service" target="_blank" class="footer_links font-12">Terms of
                            Services</a></small>
                    <small><a routerLink="/privacy-policy" target="_blank" class="font-12"
                            style="margin-left: 15px;">Privacy Policy</a></small>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>