<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate> 
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!--Loog-->
    <div class="col m-2 Logo text-center"> 
      <a [routerLink]="currentHolder" class="logo logo-light">
        <span class="logo-sm">
          <img class="Logo_image"  src="assets/images/index-logo.png" alt="" height="37px" />
        </span>
        <span class="logo-lg">         
          <img class="Logo_image" src="assets/images/logo.png" alt="" height="56px" />
        </span>
      </a>     
    </div>
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled " id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item?.isTitle">{{ item.label | translate }}</li>
        <li *ngIf="!item?.isTitle && !item?.isLayout" placement="end" >
        <!-- <li *ngIf="!item.isTitle && !item.isLayout" placement="end" ngbTooltip='{{item.label | translate}}'> -->

          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>

          <a [routerLink]="item?.link" *ngIf="!hasItems(item) && item && item.label" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item?.icon }}" *ngIf="item?.icon"></i>
            <i *ngIf="item?.faicon" class="{{ item?.faicon }}"></i>
            <span> {{ item?.label | translate }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item?.badge">{{item?.badge.text |
              translate}}</span>
          </a>     

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item?.subItems">
              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                {{ subitem.label | translate}}
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end"
                  *ngIf="subitem.badge">{{subitem.badge.text |
                  translate}}</span>
              </a>
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                    class="side-nav-link-ref">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <!-- start of the logo -->
      <div class="col d-flex align-items-center text-center justify-content-end">
        <div class="stamp" *ngIf="!isCondensed">
          <!-- <div class="large-hr"></div> -->
          <img class="logo-img" src='assets/images/aaamb-logo-new.png' alt="logo">
          <small class="text-center">
            <span>Copyright ©2024 AAAMB LLC.</span>
            <br>
            <span>All rights reserved</span>
          </small>
          <div class="clearfix">&nbsp;</div>
          <div class="d-flex">
            <small><a  routerLink="/terms-service" target="_blank" class="footer_links">Terms of Services</a></small>
            <small><a routerLink="/privacy-policy" target="_blank" class="" style="margin-left: 10px;">Privacy Policy</a></small>
          </div>
        </div>

      </div>
      <!-- end of the logo  -->
    </ul>

  </div>

</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>

</div>
<!-- Left Sidebar End -->

