import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "../services/auth.service";
import { firstValueFrom } from "rxjs";
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from "src/app/shared/services/user.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthGuard extends KeycloakAuthGuard {
  keyCloakService: any;
  productCheck:any;
  constructor(
      protected readonly router: Router,
      protected readonly keycloak: KeycloakService,
      private _userservice: UserService,
      private cookie:CookieService,
  ) {
      super(router, keycloak);
  }

  public async isAccessAllowed(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Promise<boolean> {
      // Force the user to log in if currently unauthenticated.
      if (!this.authenticated) {
          await this.keycloak.login({
              redirectUri: window.location.origin + state.url,
          });
      }
      if(!this.productCheck) {
      const currentUser = JSON.parse(this.cookie.get("user"));
        this._userservice
        .getSubscriptions(currentUser.id
        )
        .subscribe((res:[]) => {
          this.productCheck = res;
          if(res.length === 0) {
            this.router.navigate(['/unauthorized']);
            // this.cookie.deleteAll();
            // this.keycloak.logout();
          }else {}
        },
        );
      }

      // Get the roles required from the route.
      const requiredRoles = route.data.roles;
    //   // console.log("role------------"+JSON.stringify(requiredRoles));
      // Allow the user to to proceed if no additional roles are required to access the route.
      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
          return true;
      }

      // Allow the user to proceed if all the required roles are present.
      return requiredRoles.every((role) => this.roles.includes(role));
  }
}
// export class AuthGuard implements CanActivate {
//   private currentUser
//   constructor(
//     private router: Router,
//     private authenticationService: AuthenticationService
//   ) {

//   }

//    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     this.currentUser =JSON.parse(localStorage.getItem('currentUser')) || ''

//     if (this.currentUser?.token) {
//       // logged in so return true
//       return true;
//     }
//     // not logged in so redirect to login page with the return url
//     this.router.navigate(["/account/login"], {
//       queryParams: { returnUrl: state.url },
//     });
//     return false;
//   }
// }
