<div class="container-fluid p-0 body_background">
  <div class="row g-0">
    <app-loader></app-loader>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title text-center _color" id="modal-basic-title">Your password has expired. </h4>
      </div>
      <div class="modal-body">
        <div class="clearfix">&nbsp;</div>
        <div class="text-center _color">
          <p>Please feel free to contact our customer support team at credentialtoday@aaamb.com. We are here to assist you.</p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn custom-btn"
        (click)="modal.dismiss('Cross click');klogout();">Ok</button>
      </div>
    </ng-template>
  </div>
</div>