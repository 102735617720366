import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { LoaderService } from "src/app/core/services/loader.service";
import { Data, Payer } from "src/app/shared/models/data.model";
import {
  Payers,
  PayersInput,
  PayersType,
} from "src/app/shared/models/payers.model";
import {
  Group,
  Provider,
  ProviderEnrollmentData,
  ProviderEnrollmentInput,
} from "src/app/shared/models/provider.model";
import { Speciality } from "src/app/shared/models/speciality.model";
import { PayersService } from "src/app/shared/services/payers.service";
import { ProviderService } from "src/app/shared/services/provider.service";
import { UtilServcice } from "src/app/shared/util/util.service";

@Component({
  selector: "app-information-enrollments",
  templateUrl: "./enrollments.component.html",
  styleUrls: ["./enrollments.component.scss"],
})
export class CredentialEnrollmentsComponent implements OnInit {
  saveProviderEnrollmentInput: ProviderEnrollmentInput[] = [];
  private utilService = new UtilServcice();
  specialityData: Speciality[] = [];
  providerData: Provider[] = [];
  payerTypes: PayersType[] = [];
  allSelectedCsEnrollment = [];
  selectedPayers: Data[] = [];
  groupData: Group[] = [];
  ppoEpoFiltered: Payer[];
  hideRow: boolean[] = [];
  hideServiceRow: boolean[] = [];
  payers: Payers[] = [];
  data: any = [];
  govState: Payer[];
  currentPill = {};
  ppoEpo: Payer[];
  allPayers = [];
  hmo: Data[];
  providerEnrollmentInput: ProviderEnrollmentInput;
  providerEnrollment: ProviderEnrollmentData;
  selectedProviderIndex: number = -1;
  private selectedService: any;
  ppoEpoPayerType: PayersType;
  selectedPillIndex: number;
  searchPpoEpoValue = "";
  isCopySelection = true;
  selectedProvider: Data;
  PracticeUserInfo: any;
  payerSelected: any;
  isNPIType: boolean;
  LoginUserInfo: any;
  payerSubType: any;
  isActive: string;
  inputTxt = null;
  PracticeID: any;
  governmentPayers: any;
  comercialPayers: any;
  selectedServices: any;

  constructor(
    private providerService: ProviderService,
    private payersService: PayersService,
    private cdr: ChangeDetectorRef,
    private toster: ToastrService,
    private loader: LoaderService,
    private cookie: CookieService,
    private router: Router
  ) {
    //get the data from cookies and stored in the below Array obj
    const UserInfo = JSON.parse(this.cookie.get("userDetails")); // Login user Info
    const PracticeUserInfo = JSON.parse(this.cookie.get("user")); // Practice User details inc npi
    if (UserInfo) {
      this.LoginUserInfo = UserInfo;
    }
    if (PracticeUserInfo) {
      this.PracticeUserInfo = PracticeUserInfo;
      this.PracticeID = this.PracticeUserInfo.practiceData._id;
    } // Practice ID for Global
    if (this.PracticeUserInfo.practiceData.npitype[0].name == "Individual") {
      this.isNPIType = true;
    }
  }

  async ngOnInit() {
    //this.loader.isLoading.next(true);
    
    this.allPayers = JSON.parse(localStorage.getItem("allPayers"));
    // console.log("apyers");
    // console.log(this.allPayers);
    this.governmentPayers = JSON.parse(localStorage.getItem("governmentPayers"));
    // console.log("gpyers");
    // console.log(this.governmentPayers);
    this.comercialPayers = JSON.parse(localStorage.getItem("comercialPayers"));
    // console.log("cpyers");
    // console.log(this.comercialPayers);

    const selectedCsEnrollments = JSON.parse(localStorage.getItem("allSelectedCsEnrollment"));
    const saveProviderEnrollmentInput = JSON.parse(
      localStorage.getItem("saveProviderCsEnrollmentInput")
    );
    
    // console.log('selectedCsEnrollments');
    // console.log(selectedCsEnrollments)
    // console.log('saveProviderEnrollmentInput');
    // console.log(saveProviderEnrollmentInput);
   

    //this.subPayerDataCall();
    const providerIds = localStorage.getItem("selectedProviderIds");
    const groupIds = localStorage.getItem("selectedGroupIds");

    const otherText = localStorage.getItem("otherText");
    
    const selectedServices = JSON.parse(
      localStorage.getItem("allSelectedCsServices")
    );
    
    // console.log('selectedServices');
    // console.log(selectedServices);

    // console.log("otherText");
    // console.log(otherText)


    if (selectedServices) {
      this.selectedServices = selectedServices;
    }

    await this._fetchData(providerIds, groupIds);
    this.selectedProvider = this.data[0];
    this.selectedProviderIndex = 0;
    this.selectedPillIndex = 0;

    this.data.forEach((a) => (this.currentPill[a._id] = 0));


    if (selectedCsEnrollments && saveProviderEnrollmentInput) {
      this.allSelectedCsEnrollment = selectedCsEnrollments;
      this.saveProviderEnrollmentInput = saveProviderEnrollmentInput;
      this.setBindings();
    }
    this.selectedService = JSON.parse(
      localStorage.getItem("saveProviderCsServices")
    )[0];
  }

  // api to fetch all the sub payer type.
  subPayerDataCall() {
    this.payersService.getAllPayerSubtype().subscribe({
      next: (res) => {
        this.payerSubType = res;
      },
      error: (error) => {
        this.toster.error(JSON.stringify(error));
      },
      complete: () => {
        // this.loader.isLoading.next(false);
      },
    });
  }

  setBindings() {
    for (let item = 0; item < this.data?.length; item++) {
      for (let s = 0; s < this.data[item]?.services?.length; s++) {
        const cuuData = this.allSelectedCsEnrollment[this.data[item]?._id];

        if (cuuData) {
          for (let c of cuuData) {
            switch (c.type) {
              case "gov":
                const govIndex = this.data[item]?.services[s]?.gov.findIndex(
                  (a) => a._id === c._id
                );
                this.data[item].services[s].gov[govIndex].isSelected =
                  c?.isSelected;
                break;
              case "ppo":
                const ppoIndex = this.data[item]?.services[s]?.ppo.findIndex(
                  (a) => a._id === c._id
                );
                this.data[item].services[s].ppo[ppoIndex].isSelected =
                  c?.isSelected;
                break;
              case "hmo":
                this.data[item].services[s].hmo?.push(c);
                this.data[item].services[s].hmoList?.push(c);
                break;
            }
          }
        }
      }
    }
  }

  pillClick(s, dataId, selectedService, dataIndex) {
    this.loader.isLoading.next(true);
    this.currentPill[dataId] = s;
    this.selectedPillIndex = s;
    this.selectedService = selectedService;
    // console.log(this.selectedPillIndex);
    this.data[dataIndex].services.forEach((service, sindex) => {
      if (sindex !== this.selectedPillIndex) {
        this.hideServiceRow[sindex] = true;
      } else{
        this.hideServiceRow[sindex] = false;
      }
    });
    setTimeout(() => {
    this.loader.isLoading.next(false);
    }, 1300);
  }

  // for removing of the selected items.
  removeEnrollment(payer, serviceId, dataId, type) {
    const removeProviderIndex = this.data?.findIndex((a) => a._id === dataId);
    // remove from selected items
    const data = this.allSelectedCsEnrollment[dataId];
    // console.log(data);
    const index = data?.findIndex((d) => d._id == payer._id);
    if (index > -1) {
      data.splice(index, 1);
    }
    this.allSelectedCsEnrollment[dataId] = data;

    // removing the data from save data
    const sIndex = this.saveProviderEnrollmentInput?.findIndex(
      (d) =>
        d.payerid === payer._id &&
        ((d.providerid && d.providerid === dataId) ||
          (d.practiceid && d.practiceid === dataId)) &&
        d.serviceId === serviceId
    );
    if (index > -1) {
      this.saveProviderEnrollmentInput?.splice(sIndex, 1);
    }

    //remove from binding
    if (type === "x") {
      const serviceIndex = this.data[removeProviderIndex].services.findIndex(
        (a) => a._id === serviceId
      );
      
      let gIndex = 0
      if(this.data[removeProviderIndex]
        && this.data[removeProviderIndex].services
        && this.data[removeProviderIndex].services[
          serviceIndex
        ] 
        && this.data[removeProviderIndex].services[
        serviceIndex
      ].gov){
        gIndex = this.data[removeProviderIndex].services[
          serviceIndex
        ].gov?.findIndex((d) => d._id === payer._id);
      }

      if (gIndex > -1) {
        this.data[removeProviderIndex].services[serviceIndex].gov[
          gIndex
        ].isSelected = false;
      }
      const pIndex = this.data[removeProviderIndex].services[
        serviceIndex
      ].ppo?.findIndex((d) => d._id === payer._id);
      if (pIndex > -1) {
        this.data[removeProviderIndex].services[serviceIndex].ppo[
          pIndex
        ].isSelected = false;
      }

      const lIndex = this.data[removeProviderIndex].services[
        serviceIndex
      ].hmo?.findIndex((d) => d._id === payer._id);
      if (lIndex > -1) {
        this.data[removeProviderIndex].services[serviceIndex].hmo[
          lIndex
        ].isSelected = false;
      }
    } else {
    }
  }

  copySelection() {
    // console.log(this.selectedProviderIndex);
    const dataToCopy = this.data[this.selectedProviderIndex];
    // console.log(dataToCopy);

    const selectedGovPayers = [];
    const selectedPpoPayers = [];  

    dataToCopy?.gov?.forEach((payer) => {
      if(payer.isSelected){
        selectedGovPayers.push(payer._id);
      }
    });


    dataToCopy?.ppo?.forEach((payer) => {
      if(payer.isSelected){
        selectedPpoPayers.push(payer._id);
      }
    });
    
    

    this.data.forEach((provider, index) => {
      provider?.gov?.forEach((payer) => {
          payer.isSelected = false;
          if(selectedGovPayers.indexOf(payer._id) > -1){
            payer.isSelected = true;
          } 
      });

      provider?.ppo?.forEach((payer) => {
        payer.isSelected = false;
        if(selectedPpoPayers.indexOf(payer._id) > -1){
          payer.isSelected = true;
        } 
    });
      //this.data[item]?.ppo?.forEach((a) => (a.isSelected = false));
    })
    //this.data[this.selectedProviderIndex].hmoList = [];
    // for (let item = 0; item < this.data?.length; item++) {
    //   this.data[item]?.gov?.forEach((a) => (a.isSelected = false));
    //   this.data[item]?.ppo?.forEach((a) => (a.isSelected = false));
    //   //this.data[item]?.hmo?.forEach((a) => (a.isSelected = false));
    // }
  }

  // for copy of the selection
  // copySelection() {
  //   const dataToCopy =
  //     this.allSelectedCsEnrollment[this.data[this.selectedProviderIndex]?._id];

  //   this.saveProviderEnrollmentInput = [];
  //   this.allSelectedCsEnrollment = [];

  //   for (let item = 0; item < this.data?.length; item++) {
  //     this.data[item].hmo = [];
  //     for (
  //       let service = 0;
  //       service < this.data[item].services?.length;
  //       service++
  //     ) {
  //       dataToCopy?.forEach((a) =>
  //         this.changeSelection(a, true, item, service)
  //       );
  //     }
  //   }
  // }

  // adding hmo to the list
  addToList(subtypeId) {
    let hmo;
    const hmoSubtype = this.payerSubType.find((res) => res.name === "HMO");

    hmo = this.payers?.find(
      (a) =>
        a.payertype === this.ppoEpoPayerType?._id &&
        a.payersubtype === hmoSubtype._id &&
        a.name?.toLowerCase() === this.inputTxt?.toLowerCase()
    );

    if (this.inputTxt && this.inputTxt != "") {
      if (hmo) {
        // this.updateEnrollmentData(id, hmo[0]._id);

        this.inputTxt = null;
        this.data[this.selectedProviderIndex]?.services[
          this.selectedPillIndex
        ].hmo.push({
          _id: hmo?._id,
          name: hmo?.name,
          isSelected: true,
        });
        this.changeSelection(
          { _id: hmo?._id, name: hmo?.name, type: "hmo", isSelected: true },
          true,
          this.selectedProviderIndex,
          this.selectedPillIndex
        );
      } else {
        const payersInput: PayersInput = {
          name: this.inputTxt,
          payertype: this.ppoEpoPayerType?._id,
          createdon: new Date(),
          createdby: JSON.parse(this.cookie.get("user")).id,
          modifiedon: new Date(),
          modifiedby: JSON.parse(this.cookie.get("user")).id,
          subType: subtypeId,
        };
        this.payersService.postPayers(payersInput).subscribe((res: any) => {
          this.data[this.selectedProviderIndex]?.services[
            this.selectedPillIndex
          ].hmo.push({
            _id: res?._id,
            name: res?.name,
            isSelected: true,
          });
          this.data;
          this.changeSelection(
            { _id: res?._id, name: res?.name, type: "hmo", isSelected: true },
            true
          );
          this.inputTxt = null;
          this.getHMOPayers(true);
        });
      }
    }
  }

  // on checking of the
  changeValue(event, index, data) {
    // console.log(event, index, data);
    //this.allPayers = [];
    event.stopPropagation();
    if (this.hideRow[index] && this.allPayers && this.allPayers.length == 0) {
      //this.PayerDataAPICall();
    }
    for (let i = 0; i < this.providerData?.length; i++) {
      if (i !== index) this.hideRow[i] = true;
    }
    this.hideRow[index] = !this.hideRow[index];
    this.selectedProviderIndex = index;
    this.selectedProvider = data;
    this.selectedPillIndex = 0;
  }

  nextBtn() {
    // console.log(this.allSelectedCsEnrollment);
    // console.log(this.saveProviderEnrollmentInput);
    this.payersService.providerEnrollmentInputs.next(
      this.saveProviderEnrollmentInput
    );
    localStorage.setItem(
      "allSelectedCsEnrollment",
      JSON.stringify(Object.assign({}, this.allSelectedCsEnrollment))
    );
    localStorage.setItem(
      "saveProviderCsEnrollmentInput",
      JSON.stringify(this.saveProviderEnrollmentInput)
    );
    if(this.data.length == 0){
      this.router.navigate(['/information/csproviders']);
    }
  }
  removeItem(index: number, item, selectedServiceIndex = -1) {
    this.data[this.selectedProviderIndex].services[
      selectedServiceIndex
    ].gov.splice(index, 1);
    this.changeSelection(
      {
        _id: item._id,
        name: item.name,
        type: "gov",
      },
      false
    );
  }

  removePpoItem(index: number, item, selectedServiceIndex = -1) {
    this.data[this.selectedProviderIndex].services[
      selectedServiceIndex
    ].ppo.splice(index, 1);
    this.changeSelection(
      {
        _id: item._id,
        name: item.name,
        type: "ppo",
      },
      false,
      -1,
      selectedServiceIndex
    );
  }

  nextDisabled() {
    let dataSelected = [];
    for (let d of this.data) {
      d.services?.forEach((group) => {
        let pposlect = group.ppo?.some((service) => {
          return service.isSelected;
        });
        let govSelect = group.gov?.some((service) => {
          return service.isSelected;
        });
        if (pposlect || govSelect) {
          dataSelected.push(true);
        } else {
          dataSelected.push(false);
        }
      });
    }
    if (dataSelected?.every((value) => value === true)) {
      return false;
    } else {
      return true;
    }
  }

  removeProvider(id) {
    const index = this.data?.findIndex((a) => a._id === id);
    this.data?.splice(index, 1);
    // remove from save
    this.saveProviderEnrollmentInput = this.saveProviderEnrollmentInput?.filter(
      (a) =>
        (a?.practiceid && a?.practiceid !== id) ||
        (a?.providerid && a?.providerid !== id)
    );
    // remove from allSelectedSpeciality
    delete this.allSelectedCsEnrollment[id];
    // remove from local Storage
    const providerIds: string[] = JSON.parse(
      localStorage.getItem("selectedProviderIds")
    );
    if (providerIds?.includes(id)) {
      const r = providerIds?.filter((a) => a !== id);
      localStorage.setItem("selectedProviderIds", JSON.stringify(r));
    }
    const groupIds = JSON.parse(localStorage.getItem("selectedGroupIds"));
    if (groupIds?.includes(id)) {
      const r = groupIds?.filter((a) => a !== id);
      localStorage.setItem("selectedGroupIds", JSON.stringify(r));
    }
    let saveProviderCsServices: any[] = JSON.parse(
      localStorage.getItem("saveProviderCsServices")
    );

    if (saveProviderCsServices && saveProviderCsServices?.length > 0) {
      saveProviderCsServices = saveProviderCsServices?.filter(
        (a) =>
          (a?.practiceid && a?.practiceid !== id) ||
          (a?.providerid && a?.providerid !== id)
      );
      localStorage.setItem(
        "saveProviderCsServices",
        JSON.stringify(saveProviderCsServices)
      );
    }
    const allSelectedCsServices = localStorage.getItem("allSelectedCsServices");
    if (allSelectedCsServices && allSelectedCsServices.length) {
      if (
        !saveProviderCsServices ||
        (saveProviderCsServices && saveProviderCsServices.length === 0)
      ) {
        localStorage.removeItem("allSelectedCsServices");
      } else {
      }
    }
    this.nextBtn();
  }
  async _fetchData(providerIds, groupIds) {
    this.loader.isLoading.next(true);
    this._fetchProviderEnrollment();

    const groupData = await firstValueFrom(this.providerService.getGroups());
    this.groupData = groupData;

    this.groupData = this.groupData?.filter((a) => groupIds?.includes(a._id));

    for (
      let i = 0;
      i < this.providerData?.length + this.groupData?.length;
      i++
    ) {
      if (i === 0) this.hideRow[i] = true;
      else this.hideRow?.push(true);
    }

    for (let i = 0; i < this.groupData?.length; i++) {
      const data: Data = {
        _id: this.groupData[i]._id,
        name: this.groupData[i].name,
      };

      this.data?.push(data);
    }
    const providerData = await firstValueFrom(
      this.providerService?.getProviders()
    );
    this.providerData = providerData;

    this.providerData = this.providerData?.filter((a) =>
      providerIds?.includes(a._id)
    );
    

    for (
      let i = 0;
      i < this.providerData?.length + this.groupData?.length;
      i++
    ) {
      if (i === 0) this.hideRow[i] = false;
      else this.hideRow?.push(true);
    }

    for (let i = 0; i < this.providerData?.length; i++) {
      const data: Data = {
        _id: this.providerData[i]._id,
        name:
          this.providerData[i].firstname + " " + this.providerData[i].lastname,
      };
      this.data?.push(data);
    }
   
    if (this.data?.length > 1) {
      this.isCopySelection = false;
    }
    
   

    this.loader.isLoading.next(false);
    const payerType = await firstValueFrom(this.payersService.getPayerTypes());
    this.payerTypes = payerType;

    
    this.getGovStateSubPayers(this.governmentPayers);
    this.getComercialPayers(this.comercialPayers);
    
    this.loadServices();
  }

  // private loadUserPayers(){
  //   for(let i=0; i < this.data.length ; i++ ){
  //     this.data[i].gov =  JSON.parse(JSON.stringify(this.governmentPayers));
  //     this.data[i].ppo = JSON.parse(JSON.stringify(this.comercialPayers));
  //   }
  //   // console.log(this.data);
  // }

  private loadServices(){
    this.data.forEach((data, index) => {
      data.services =  this.selectedServices[data._id];
      data.services.forEach((service, sindex) => {
        // if (sindex !== 0) {
        //   this.hideServiceRow[sindex] = true;
        // } else{
        //   this.hideServiceRow[sindex] = false;
        // }
        service.gov = JSON.parse(JSON.stringify(this.governmentPayers));
        service.ppo = JSON.parse(JSON.stringify(this.comercialPayers));
      });
    });
    
    // console.log(this.data);
  }

  PayerDataAPICall() {
    try {
      this.loader.isLoading.next(true);

      this.payersService.getPayers().subscribe((payers) => {
        // this.payersService.setAllPayers(payers);
        this.loader.isLoading.next(false);
        // console.log(payers);
        this.allPayers = payers;
        this.getGovStateSubPayers(payers);
        this.getComercialPayers(payers);
      });
    } catch (e) {}
  }

  getGovStateSubPayers(payers:any) {
    if(payers){
    let govPayerType = this.payerTypes.filter((res)=>res.name.toLowerCase().includes('gov'))
    let govPayers = payers.filter((res)=>res.payertype == govPayerType[0]._id && res._id !== '64f42568ca3f5b36deed0d3a' )   
    govPayers.forEach((res)=>{
      res.type = 'gov'
    })
    this.governmentPayers = govPayers;
  }
  }

  getComercialPayers(payers:any){
    if(payers){
    let govPayerType = this.payerTypes.filter((res)=>res.name.toLowerCase().includes('gov'))
    let comPayers = payers.filter((res)=>res.payertype !== govPayerType[0]._id && res._id !== '64f42568ca3f5b36deed0d3a' )
    
    comPayers.forEach((res)=>{
      res.type = 'ppo'
    })   
    this.comercialPayers = comPayers;
    }
  }

  _fetchProviderEnrollment() {
    this.providerService.getProviderEnrollment().subscribe((res) => {
      this.providerEnrollment = res?.data;
    });
  }
  searchPpoEpo(value: string) {
    value = value.trim()?.toLowerCase();
    this.searchPpoEpoValue = value;
    if (value) {
      this.data[this.selectedProviderIndex].ppo = this.ppoEpo?.filter((data) =>
        data.name?.toLowerCase()?.includes(value)
      );
    } else {
      this.data[this.selectedProviderIndex].ppo = this.ppoEpo;
    }
    this.cdr.detectChanges();
  }

  addNewPpoEpo(subTypeId) {
    const payer = {
      name: this.searchPpoEpoValue,
      payertype: this.ppoEpoPayerType?._id,
      createdon: new Date(),
      createdby: JSON.parse(this.cookie.get("user")).id,
      modifiedon: new Date(),
      modifiedby: JSON.parse(this.cookie.get("user")).id,
      subType: subTypeId,
    };
    this.payersService.postPayers(payer).subscribe((a) => {
      this.ppoEpo?.splice(0, 0, {
        _id: a?._id,
        name: a?.name,
        type: "ppo",
        isSelected: false,
      });
      // this.data[this.selectedProviderIndex].ppo = [...this.ppoEpo];
      this.data?.forEach((d) => {
        d.services.forEach(
          (a) => (a.ppo = JSON?.parse(JSON.stringify(this.ppoEpoFiltered)))
        );
      });

      this.data[this.selectedProviderIndex].ppo = [...this.ppoEpo];
    });
    this.searchPpoEpoValue = "";
  }

  getEnrollmentDetails() {
    const localStorageSavedProviderCsServices: any[] = JSON.parse(
      localStorage.getItem("saveProviderCsServices")
    );

    this.data.forEach((a) => {
      localStorageSavedProviderCsServices.forEach((l) => {
        if (
          (l?.practiceid && a._id === l?.practiceid) ||
          (l?.providerid && a._id === l?.providerid)
        ) {
          if (a?.services?.length > 0) {
            a.services.push({
              servaiceName: l?.serviceName,
              serviceId: l?.serviceId,
              otherServiceText: l?.otherServiceText,
              type: l?.type,
            });
          } else {
            a.services = [
              {
                servaiceName: l?.serviceName,
                serviceId: l?.serviceId,
                otherServiceText: l?.otherServiceText,
                type: l?.type,
              },
            ];
          }
        }
      });
    });

    this.govState = [];
    const govStatePayerType = this.payerTypes?.filter(
      (a) => a.name === "GOV/STATE"
    );

    if (govStatePayerType) {
      const govState = this.payers?.filter(
        (a) => a.payertype === govStatePayerType[0]?._id
      );

      for (let i = 0; i < govState?.length; i++) {
        if (govState[i]._id !== "64f42568ca3f5b36deed0d3a") {
          /// remove after object confirmation this is for 05/09/2023
          const data: Payer = {
            _id: govState[i]._id,
            name: govState[i].name,
            type: "gov",
            isSelected: false,
          };
          this.govState?.push(data);
        }
      }

      this.data?.forEach((d) => {
        d.services.forEach(
          (a) => (a.gov = JSON.parse(JSON.stringify(this.govState)))
        );
      });
    }

    this.ppoEpo = [];
    this.ppoEpoPayerType = this.payerTypes?.find(
      (a) => a.name === "Commercials/Managed Care"
    );

    const ppoEpoSubtype = this.payerSubType.find(
      (res) => res.name === "EPO/PPO"
    );

    let ppoEpo = this.payers?.filter(
      (a) =>
        a.payertype === this.ppoEpoPayerType?._id &&
        a.payersubtype === ppoEpoSubtype?._id
    );

    // console.log(ppoEpo);
    this.ppoEpoFiltered = ppoEpo;
    this.data?.filter((d) => {
      d.services.filter(
        (a) => (a.ppo = JSON.parse(JSON.stringify(this.ppoEpoFiltered)))
      );
    });

    //this.getHMOPayers(false);
    this.data?.forEach((a) => (a.hmoList = []));
  }

  async getHMOPayers(reload) {
    if (reload) {
      const payers = await firstValueFrom(this.payersService.getPayers());
      this.payers = payers;
    }
    this.hmo = [];

    const hmoSubType = this.payerSubType.find((res) => res.name == "HMO");
    const hmo = this.payers?.filter(
      (a) =>
        a.payertype[0] === this.ppoEpoPayerType?._id &&
        a.payersubtype[0] === hmoSubType?._id
    );
    for (let i = 0; i < hmo.length; i++) {
      const data: Data = {
        _id: hmo[i]._id,
        name: hmo[i].name,
        type: "hmo",
        isSelected: false,
      };
      this.hmo?.push(data);
    }
    this.data?.forEach((d) => d.services.forEach((a) => (a.hmo = [])));
  }

  // changes of the selection
  changeSelection(
    data: Payer,
    isSelected: boolean,
    item: number = -1,
    serviceIndex = -1,
    isGovernment = false,
    type = 'gov',
    prId = -1
  ) {
    // console.log(item);
    // console.log(serviceIndex);
    // console.log(isGovernment);
    // console.log();

    // console.log(data);
    if (isSelected) {
      const providerId = this.data?.filter((a) =>
        item > -1
          ? a._id === this.data[item]?._id
          : a._id === this.selectedProvider?._id
      )[0]?._id;
      // console.log(data);
      const practiceId = this.data?.filter((a) =>
        item > -1
          ? a._id === this.data[item]?._id
          : a._id === this.selectedProvider?._id
      )[0]._id;

      // console.log(this.data[item].services[serviceIndex]);
      const providerEnrollmentInput = {
        providerid: providerId,
        payerid: data?._id,
        facilityid: null,
        practiceid: practiceId,
        selected: true,
        createdon: new Date(),
        createdby: "string",
        modifiedon: new Date(),
        modifiedby: "string",
        serviceId: this.data[item].services[serviceIndex]._id,
      };
      if (
        !this.saveProviderEnrollmentInput?.find(
          (a) =>
            a.payerid === providerEnrollmentInput.payerid &&
            ((providerEnrollmentInput.providerid &&
              a.providerid === providerEnrollmentInput.providerid) ||
              (providerEnrollmentInput.practiceid &&
                a.practiceid === providerEnrollmentInput.practiceid))
        )
      ) {
        this.saveProviderEnrollmentInput?.push(providerEnrollmentInput);

        if (
          this.allSelectedCsEnrollment[
            providerId !== "" ? providerId : practiceId
          ]
        ) {
          this.allSelectedCsEnrollment[
            providerId !== "" ? providerId : practiceId
          ]?.push(
            Object.assign(data, {
              serviceId:this.data[item].services[serviceIndex]._id,
            })
          );
        } else {
          this.allSelectedCsEnrollment[
            providerId !== "" ? providerId : practiceId
          ] = [
            Object.assign(data, {
              serviceId:this.data[item].services[serviceIndex]._id,
            }),
          ];
        }
      }

      if (!isGovernment) {
        this.selectedPayers.push(data);
      }

      let govIndex;
      this.data[item]?.services?.forEach((d) => {
        govIndex = d?.gov?.findIndex((a) => a._id === data._id);
        if (govIndex > -1 && this.data[item].services[serviceIndex].gov) {
          this.data[item].services[serviceIndex].gov[govIndex].isSelected =
            true;
          this.data[item].services[serviceIndex].gov[govIndex].prId =
            prId;  
        }
      });

      this.data[item]?.services?.forEach((d) => {
        const ppoIndex = d?.ppo?.findIndex((a) => a._id === data._id);
        if (ppoIndex > -1) {
          this.data[item].services[serviceIndex].ppo[ppoIndex].isSelected =
            true;
          this.data[item].services[serviceIndex].ppo[ppoIndex].prId =
            prId;
        }
      });

      // console.log(this.data);

      // add to this.data in case of copy selection
      if (item > -1 && serviceIndex > -1) {
        // console.log(type);
        switch (type) {
          case "gov":
            let govIndex;
            this.data[item]?.services?.forEach((d) => {
              govIndex = d?.gov?.findIndex((a) => a._id === data._id);
              if (govIndex > -1) {
                this.data[item].services[serviceIndex].gov[
                  govIndex
                ].isSelected = true;
                this.data[item].services[serviceIndex].gov[govIndex].prId =
                prId; 
              }
            });

            break;
          case "ppo":
            let ppoIndex;

            this.data[item]?.services?.forEach((d) => {
              ppoIndex = d?.ppo?.findIndex((a) => {
                a._id === data._id;
              });
              if (ppoIndex > -1) {
                this.data[item].services[serviceIndex].ppo[
                  ppoIndex
                ].isSelected = true;
                this.data[item].services[serviceIndex].ppo[ppoIndex].prId =
                prId;
              }
            });
            break;
          case "hmo":
            this.data[item]?.services?.forEach((d) => {
              const hmoIndex = d?.hmo?.findIndex((a) => {
                a._id === data._id;
              });
              if (hmoIndex > -1) {
                this.data[item].services[serviceIndex].hmo[
                  hmoIndex
                ].isSelected = true;
                this.data[item].services[serviceIndex].hmo.push(data);
              }
            });

            break;
        }
      }
    } else {
      this.removeEnrollment(
        data,
        this.data[item > -1 ? item : this.selectedProviderIndex].services[
          serviceIndex > -1 ? serviceIndex : this.selectedPillIndex
        ]._id,
        this.selectedProvider?._id,
        "x"
      );
    }
  }

  public epoCheck(ppoList: any): boolean {
    let epo = "";
    if (this.searchPpoEpoValue) {
      epo = ppoList?.find((res) =>
        res.name
          .toLowerCase()
          .split("")
          .join()
          .includes(this.searchPpoEpoValue.toLowerCase().split("").join())
      );
    } else {
      epo = "";
    }

    if (epo) {
      return false;
    } else {
      return true;
    }
  }

  myFunction(indx) {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById(`myInput${indx}`);
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "flex";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  clearSearch(indx) {
    var input;
    input = document.getElementById(`myInput${indx}`);
    input.value = "";
    this.myFunction(indx);
  }

  chechValue(indx) {
    var input, filter, table, tr, td, i, txtValue;
     input = document.getElementById(`myInput${indx}`);
   return  input.value !== ''? true :false;
  }

   addSpaceBeforeCaps(text) {
    return text.replace(/([A-Z])/g, ' $1').trim();
    }
    
}
