import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  ProviderSpecialityData,
  ProviderSpecialityInput,
  Speciality,
  SpecialityInput,
} from "../models/speciality.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SpecialityService {
  providerSpecialtyInputs = new BehaviorSubject<any[]>([]);
  specialityList: any;
  getSpecialities() {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<Speciality[]>(
      environment.mainServiceAPI + `/specialties`
    );
  }

  get(id: string) {
    return this.http.get<Speciality>(
      environment.serviceAPI + `/speciality/` + id
    );
  }
  getSpecialityById(id: string) {
    return this.http.get<SpecialityInput>(
      environment.mainServiceAPI + `/specialties/` + id
    );
  }

  post(facility: SpecialityInput) {
    return this.http
      .post<any>(environment.serviceAPI + `/speciality/add`, facility)
      .subscribe((res) => {
        return res;
      });
  }

  put(id: string, facility: SpecialityInput) {
    return this.http
      .post<any>(environment.serviceAPI + `/speciality/` + id, facility)
      .subscribe((res) => {
        return res;
      });
  }

  delete(id: string) {
    return this.http.delete<Speciality>(
      environment.serviceAPI + `/speciality/` + id
    );
  }

   postProviderSpeciality(input: ProviderSpecialityInput):Observable<any> {
    return this.http
      .post<any>(environment.serviceAPI + `/providerspeciality/add`, input)
  }

  getProviderSpeciality() {
    return this.http.get<ProviderSpecialityData>(
      environment.serviceAPI + `/providerspeciality/`
    );
  }

  getProviderspecialityWithProviderId(providerId) {
    return this.http.get<any>(
      `${environment.serviceAPI}/providerspeciality/projsequence/${providerId}`
    );
  }

  // getQuote(param: any): Observable<any> {
  //   return this.apiService
  //     .post('Home/GetQuote/', param)
  //     .pipe(map((response) => <any[]>response));
  // }

  getProviderspecialityWithSequnceId(projSequenceId) {
    return this.http.get<any>(
      `${environment.serviceAPI}/providerspeciality/projsequence/${projSequenceId}`
    );
  }

  getMultiSpecialities(specailities):Observable<any> {
    return this.http
      .post<any>(environment.mainServiceAPI + `/specialties/getmultispecialties`, specailities)
  }

  getProviderspecialityWithPracticeId(practiceid) {
    return this.http.get<any>(
      `${environment.serviceAPI}/providerspeciality/practice/${practiceid}`
    );
  }

  setSpecialityServices(specList): any {
    this.specialityList = specList;
  }

  getSpecialityServices(): any {
    return this.specialityList;
  }

  getSpecialitySubTypes() {
    return this.http.get<any>(
      `${environment.mainServiceAPI}/specialitysubtype`
    );
  }

}