import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Payers, PayersInput, PayersType } from "../models/payers.model";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PayersService {

  private CountAPI = new BehaviorSubject('Counts');
  private CSCountAPI = new BehaviorSubject('Counts');
  NBgetCount = this.CountAPI.asObservable();
  CSgetCount = this.CSCountAPI.asObservable();
  payerInfo: any;
  payers$ = new Subject();

  constructor(private http: HttpClient) {}

  NBsetCount(CountAPI : any){
    this.CountAPI.next(CountAPI);
  }

  CSsetCount(CountAPI : any){
    this.CSCountAPI.next(CountAPI);
  }

  setAllPayers(data){
    // console.log(data);
    this.payers$.next(data);
  }

  getAllPayers(){
    return this.payers$.asObservable();
  }
 

  providerEnrollmentInputs = new BehaviorSubject<any[]>([]);
  getPayerTypes() {
    return this.http.get<PayersType[]>(
      environment.mainServiceAPI + `/payertype`
    );
  }

  getSubtypePayers(id: any) {
    return this.http.get<Payers[]>(
      environment.mainServiceAPI + `/payers/payersubtype/` + id
    );
  }

  getPayers() {
    return this.http.get<Payers[]>(environment.mainServiceAPI + `/payers`);
  }

  deletePayer(id: string) {
    return this.http.delete<any>(environment.mainServiceAPI + `/payers/` + id);
  }

  postPayers(input: PayersInput) {
    return this.http.post<any>(
      environment.mainServiceAPI + `/payers/add`,
      input
    );
  }

  getPayerById(id) {
    return this.http.get<any>(environment.mainServiceAPI + `/payers/` + id);
  }

  getPayerSubtypeId(id) {
    return this.http.get<any>(
      environment.mainServiceAPI + `/payersubtype/payertype/` + id
    );
  }

  getAllPayerSubtype() {
    return this.http.get<any>(
      environment.mainServiceAPI + `/payersubtype`
    );
  }

  getproviderenrollment(id: any) {
    return this.http.get<Payers[]>(
      environment.serviceAPI + `/providerenrollment/projsequence/` + id
    );
  }

  getmultipayers(data){
    return this.http.post<any>(
      environment.mainServiceAPI + `/payers/getmultipayers`, data);
  }

  //cs service
  getprojectCredentialsByprojectseq(id:any){
    return this.http.get<Payers[]>(
      environment.serviceAPI + `/projectCredentialsByprojectseq?projectSeq=` + id
    );
  }

  getcredentialTypes(){
    return this.http.get( environment.serviceAPI + '/credentialType');
  }

  getuploadeddoccount(data:any){
    // return this.http.get<any>(environment.mainServiceAPI + `/getuploadeddoccount`, data);
    return this.http.post<any>(environment.mainServiceAPI + `/getuploadeddoccount/`, data);
    }
  // http://localhost:8005/getProjectBreakups/projsequence/:projsequence/:providerType
  getPayersCount(seq, providerType) {
    return this.http.get<any>(
      environment.serviceAPI + `/getProjectBreakups/projsequence/` + seq + '/' + providerType);
  }
  //http://3.6.5.222:8005/getcsprojectbreakups/projsequence/:projsequence/:providerType
  getCSPayersCount(seq, providerType) {
    return this.http.get<any>(
      environment.serviceAPI + `/getcsprojectbreakups/projsequence/` + seq + '/' + providerType);
  }

  addNotes(data:any){
    // return this.http.get<any>(environment.mainServiceAPI + `/getuploadeddoccount`, data);
    return this.http.post<any>(environment.serviceAPI + `/payernotes/add`, data);
    }

  setPayerService(payerData) {
    this.payerInfo = payerData
  }

  getPayerInfo() {
    return this.payerInfo;
  }

  getNotes(seq,practice,provider,type) {
   return this.http.get<any>(
      environment.serviceAPI + `/payernotes/` + seq + '/' + practice + '/'+ practice +'/' + provider);
  }

}
