import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"],
})
export class ChangePasswordDialogComponent {
  constructor(
    private modalService: NgbModal,
    private router:Router
    ) {}

  public close() {
    this.modalService.dismissAll();
  }


  public changePassword(){
    this.close()
    this.router.navigate(['/settings']);
  }
}
