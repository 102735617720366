<div class="card">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        Old Password will be the One Time Password emailed during account set-up.
    </div>
    <div class="modal-footer">
        <button  class="btn btn-primary" (click)="changePassword()">Change Password</button>
    </div>
</div>