import { Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AuthenticationService } from "../../../core/services/auth.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { OrderService } from "src/app/shared/services/order.service";
import { Order } from "src/app/shared/models/order.model";
import { ToastrService } from "ngx-toastr";
import { LoaderService } from "src/app/core/services/loader.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { HttpErrorResponse } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
/**
 * Login-2 component
 */
export class LoginComponent implements OnInit {
  public isLoggedIn: boolean = false;
  public userProfile: KeycloakProfile | null = null;
  private currentUserPractice: string = "";
  loginForm: UntypedFormGroup;
  submitted = false;
  error = "";
  returnUrl: string;
  showPassword = false;
  allOrders: Order[] = [];
  public loginErrorMessage: string = "";
  password;
  private statusCode: boolean = false;
  public userDetails: any;
  isPasswordExpired: any = false;
  modalReference: any;
  @ViewChild("content") content;

  constructor(
    private readonly keycloak: KeycloakService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private oderService: OrderService,
    private tosterService: ToastrService,
    private modalService: NgbModal,
    private cookie:CookieService,
    private loaderService: LoaderService,
  ) {}

  // set the currenr year
  year: number = new Date().getFullYear();

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      rememberMe: [false],
    });

    localStorage.clear()
    // if(this.isPasswordExpired){
    //   this.keycloak.logout();
    // }
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();

 
      await this.getLoggedInUserData(this.userProfile.email);
    } else {
      this.keycloak.login();
    }
  }

  async getLoggedInUserData(email: string) {
    this.loaderService.isLoading.next(true);
    await this.authService
      .signedInUserData((email).toLowerCase())
      .pipe(first())
      .subscribe({
        next: (res: any) => {
          if(res?.data){
          this.cookie.set("user", JSON.stringify(res.data));
          // this.cookie.set('user',JSON.stringify(res.data))
          // this.authService.currentUserSubject.next(res.data);
          // this.authService.setCurrentUser(res.data);
          this.getUserDetails(res.data);
        }else{
          this.keycloak.login()

          // localStorage.clear();
          
          // this.loaderService.isLoading.next(false);
          // this.isPasswordExpired = true;
          // this.modalReference = this.modalService.open(this.content);
          // this.modalReference.result.then(
          //   (result) => {
          //   },
          //   (reason) => {
          //   }
          // );
          // setTimeout(() => {
          //   this.keycloak.logout();
          // }, 5000);
        }
      },
        error: (errorRes: HttpErrorResponse) => {
          this.loaderService.isLoading.next(false);
        },
      });
  }

  klogout(){
    this.keycloak.logout()
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1,
      },
    },
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  // function for getting current user practice
  private getCurrentUserPractice() {
    const currentUser = JSON.parse(this.cookie.get("user"));

    this.authService.getUsersDetails(currentUser.id).subscribe({
      next: (res) => {
        this.userDetails = res;
        // localStorage.setItem("userDetails", JSON.stringify(this.userDetails));
        this.cookie.set("userDetails",JSON.stringify(this.userDetails))
      },
      error: (error) => {
        this.tosterService.error(JSON.stringify(error))
      },
    });

    if (currentUser) {
 
      this.cookie.set("NPIType", currentUser?.practiceData?.npitype[0]?.name);
      // localStorage.setItem("NPIType", currentUser.practiceData.npitype[0].name);
      this.cookie.set("NPIType", currentUser.practiceData.npitype[0].name);
    }
    this.currentUserPractice = currentUser?.practiceData?._id;
  }

  removeWhiteSpaces(event) {
    this.password = event.target.value.replace(/\s/g, "");
  }

  private async getUserDetails(data) {
    // fetching of the npi type

    await this.getCurrentUserPractice();
    let user = JSON.parse(this.cookie.get("user"));
    // fetching of the orders
   
    this.oderService.getAllOrders().subscribe((a) => {
      const orders = a;
      this.allOrders = orders.filter(
        (res: Order) => res.practiceid === this.currentUserPractice
      );
      this.loaderService.isLoading.next(false);
      if (user.usertype.name.toLowerCase().includes("admin")) {
        this.router.navigate(["/dashboard/admin"]);
      } else if (
        user.usertype.name.toLowerCase().includes("staff") &&
        this.allOrders?.length <= 0
      ) {
        if (this.userDetails && this.userDetails.updatepassword == false) {
          this.modalService.open(ChangePasswordDialogComponent, {
            centered: true,
          });
        }
        this.router.navigate(["/dashboard/home"]);
      } else if (
        user.usertype.name.toLowerCase().includes("provider") &&
        this.allOrders?.length > 0
      ) {
        if (this.userDetails && this.userDetails.updatepassword == false) {
          this.modalService.open(ChangePasswordDialogComponent, {
            centered: true,
          });
        }
        this.router.navigate(["/dashboard/default"]);
      }else if(user.usertype.name.toLowerCase().includes('staff') && this.allOrders?.length>0){
        this.router.navigate(["/dashboard/default"]);
        if (this.userDetails && this.userDetails.updatepassword == false) {
          this.modalService.open(ChangePasswordDialogComponent, {
            centered: true,
          });
        }
      }else{
        if (this.userDetails && this.userDetails.updatepassword == false) {
          this.modalService.open(ChangePasswordDialogComponent, {
            centered: true,
          });
        }
        this.router.navigate(["/dashboard"]);
      }
    },
    (error)=>{
 
    });
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
