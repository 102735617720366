import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  Group,
  PrerequisiteInput,
  Provider,
  ProviderInput,
} from "../models/provider.model";
import {
  User,
  UserInput,
  UserType,
  UserTypeInput,
} from "../models/user.model";
import { Observable, lastValueFrom } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class UserService {
  constructor(private http: HttpClient,
    public _cookiesService: CookieService,
  ) {}
  readonly kcURL = environment.kcapiHost;
  httpOptions = {
    headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
    }),
};

  async post(userType: UserTypeInput) {
    this.http
      .post<any>(environment.mainServiceAPI + `/usertype/add`, userType)
      .subscribe((res) => {
        return res;
      });
  }

  getUserType() {
    return this.http.get<UserType[]>(environment.mainServiceAPI + `/usertype`);
  }

  public postUser(userType: UserInput):Observable<any> {
    return this.http.post<any>(environment.mainServiceAPI + `/user/add`, userType)
  }

  getSubscriptions(id) {
    return this.http.get(
        environment.ShoppingAPI + `/`+ id
    );
}

  getUsers() {
    return this.http.get<User[]>(environment.mainServiceAPI + `/user`);
  }

  getUsers_practice_id(id) {
    return this.http.get<User[]>(environment.mainServiceAPI + `/user/practice/`+ id);
  }
 
  updateUser(id, input:any) {
    return this.http.put<User[]>(environment.mainServiceAPI + `/user/${id}`, input);
  }

  //notifying the users   
  notifyProjectUsers(orders){
    return this.http.post<any>(environment.mainServiceAPI + '/notifyProjectUsers',orders)
  }
  // http://3.6.5.222:8003+`/shopping/orders/${_id}`)
  getProductIds(id){
    return this.http.get<User[]>(environment.mainSubscriptionAPI + `/shopping/orders/`+ id);
  }

  // http://3.6.5.222:8002 + `/product/${_id}`)

  getProductDetails(id){
    return this.http.get<User[]>(environment.SubscriptionAPI + `/product/`+ id);
  }


  GetAccessToken(): Promise<any>{
    try {
        return lastValueFrom(
            this.http.post(this.kcURL + "/token", "")
        );
    } catch (error: any) {
        throw new Error(error.message);
    }
}


AddKCUser(data: User,token: string): Promise<any> {
  try {

      if (token) {
          this.httpOptions = {
              headers: new HttpHeaders({
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${token}`,
              }),
          };
          // console.log(JSON.stringify(data));
          return lastValueFrom(
              this.http.post(this.kcURL + "/user", data, this.httpOptions)
          );
      }
  } catch (error: any) {
      throw new Error(error.message);
  }
}

deleteKCUser(id,token: string): Promise<any> {
  try {

      if (token) {
          this.httpOptions = {
              headers: new HttpHeaders({
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `Bearer ${token}`,
              }),
          };
          return lastValueFrom(
              this.http.get(this.kcURL + "/user/delete/" + id, this.httpOptions)
          );
      }
  } catch (error: any) {
    // console.log(error);
      throw new Error(error.message);
  }
}


// soft delete of the user 
public softDelete(id):Observable<any>{
  return this.http.get(environment.mainServiceAPI+'/user/softDelete'+`/${id}`)
}

public delete(id):Observable<any>{
  return this.http.delete(environment.mainServiceAPI+'/user'+`/${id}`)
}

}
