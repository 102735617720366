<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="page-title-box d-flex align-items-center">
          <div class="page-title-left">
            <h2 class="card-title">Credentialing Services</h2>
          </div>
          <span class="ml-5 m-top-5">
            <button class="btn btn_popover_noborder me-2" placement="end"
              ngbPopover="To create/access the demographic file update of an entity/provider with payer(s) & its workflow status." popoverTitle="Credentialing Services">
              <i class="fa fa-info-circle _info_color_icon"></i>
            </button>
          </span>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="card-sub-title text-gray-600 mb-4">Enrollments/Payer(s)</h4>
          </div>
        </div>

        <div id="basic-example" role="application" class="wizard clearfix">
          <div class="steps clearfix">
            <ul role="tablist">
              <li role="tab" class="done" aria-disabled="true">
                <a id="basic-example-t-1" href="javascript: void(0);" aria-controls="basic-example-p-1"><span
                    class="number">01</span>
                  <p>Choose a<b>Service</b></p>
                </a>
              </li>
              <li role="tab" class="done" aria-disabled="true">
                <a id="basic-example-t-2" href="javascript: void(0);" aria-controls="basic-example-p-2"><span
                    class="number">02</span>
                    <p  *ngIf="!isNPIType">Choose <b>Practice/Providers </b></p>
                    <p *ngIf="isNPIType">Choose <b>Practice </b></p>
                </a>
              </li>
              <li role="tab" class="done" aria-disabled="true" aria-selected="true">
                <a id="basic-example-t-3" href="javascript: void(0);" aria-controls="basic-example-p-3"><span
                    class="number">03</span>
                  <p>
                    Choose
                    <b>Credentialing Service</b>
                  </p>
                </a>
              </li>
              <li role="tab" class="first current" aria-disabled="false">
                <a id="basic-example-t-4" href="javascript: void(0);" aria-controls="basic-example-p-5"><span
                    class="number">04</span>
                  <p>
                    Choose
                    <b>Enrollments/Payer(s)</b>
                  </p>
                </a>
              </li>
              <li role="tab" class="disabled last" aria-disabled="true">
                <a id="basic-example-t-5" href="javascript: void(0);" aria-controls="basic-example-p-5"><span
                    class="number">05</span>
                  <p>Review and Complete <b>Your Project</b></p>
                </a>
              </li>
            </ul>
          </div>
          <div class="content-con">
            <div class="row">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-md-9">
                    <h4 class="h4 text-black ellipsis">
                      Select payer(s) that your practice/provider need to contact
                    </h4>
                  </div>
                  <!-- <div class="col-md-3">
                    <a [ngClass]="{
                        disabled: isCopySelection,
                        'custom-link': true
                      }" style="cursor: pointer" (click)="copySelection()">Copy Selection</a>
                  </div> -->
                </div>
                <div class="accordion">
                  <div class="card" *ngFor="let src of data; let i = index" [ngClass]="{ expanded: hideRow[i] }">
                    <div class="card-header" id="headingOne" (click)="changeValue($event, i, src)">
                      <div class="row">
                        <div class="col-lg-10">
                          <h5 class="mb-0">
                            {{ src.name }}
                          </h5>
                        </div>
                        <div class="col-lg-2">
                          <div class="justify-content-end">
                            <a *ngIf="hideRow[i]">
                              <i class="bx bx-chevron-down"></i>
                            </a>
                            <a *ngIf="!hideRow[i]">
                              <i class="bx bx-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div [ngClass]="{ show: !hideRow[i] }" class="collapse">
                      <div class="enroll-con">
                        <!--Nav Pills-->
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                          <li class="nav-item" role="presentation" *ngFor="let service of src?.services; let s = index">
                            <button class="nav-link" [ngClass]="{ active: s == 0 }" id="pills-service-tab"
                              data-bs-toggle="pill" data-bs-target="#pills-service" type="button" role="tab"
                              aria-controls="pills-service" aria-selected="true"
                              (click)="pillClick(s, src?._id, service, i)">
                              {{ addSpaceBeforeCaps(service?.type) | titlecase}} - {{ service?.name }} {{service?.otherText | titlecase}}
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div class="tab-pane fade show active" id="pills-service" role="tabpanel"
                            aria-labelledby="pills-service-tab">
                            <div class="card-body">
                              <h5 class="font-size-14">GOV/STATE</h5>
                              <div class="row g-0">
                                <ul class="select-service mt-1" *ngIf="selectedPillIndex > -1">
                                  <li *ngFor="
                                      let gov of src?.services[
                                        selectedPillIndex
                                      ].gov;
                                      let j = index
                                    " class="col-sm-4" style="margin-top: 5px">
                                  
                                    
                                    <div style="width: 100%">
                                      <label [for]="govState + j" [style]="gov.isSelected ? 'color: #004745;font-weight: 600' : ''">{{
                                        gov.name
                                        }}</label>&nbsp; &nbsp;
                                      <input style="float: right"  type="checkbox"
                                        class="form-check-input fr" [id]="govState + j" [value]="gov.serviceId"
                                        (change)="changeSelection(gov, $event?.target?.checked, i, selectedPillIndex, true, 'gov', src._id)"
                                        [(ngModel)]="gov.isSelected"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <h5 class="font-size-14">
                                COMMERCIALS/MANAGED CARE
                              </h5>
                              <div class="row mt-1">
                                <div class="col-lg-12">
                                  <div>
                                    <table class="table table-responsive table-striped datatables table-services"  cell-padding="0" cell-spacing="0">
                                      <thead>
                                        <tr>
                                          <td colspan="2" style="padding-left: 0.25rem;line-break: anywhere;">
                                            <div class="input-group addlistgrp">
                                              <input [id]="'myInput'+i" 
                                                placeholder="Search" type="text" class="form-control" (keyup)="myFunction(i)"
                                                />
                                                <div *ngIf="chechValue(i)" class="input-group-append icon-position">
                                                  <span class="cross-icon" (click)="clearSearch(i)"><i class='bx bx-x'></i></span>
                                                </div>
                                              <!-- <div class="input-group-append"> -->
                                                <!-- <button *ngIf="epoCheck(EPOList_arr)&&  searchPpoEpoValue!=='' "
                                                  class="btn btn-outline-secondary" (click)="addNewPpoEpo(val._id)"
                                                  type="button">
                                                  CREATE
                                                </button> -->
                                              <!-- </div> -->
                                            </div>
                                          </td>
                                        </tr>
                                      </thead>
                                      <ng-container *ngIf="!hideRow[i] && src && src?.services && src?.services[
                                      selectedPillIndex
                                    ]">
                                        <tbody id="myTable" style="
                                            width: 100%;
                                            display: block;
                                            border: 1px solid white;
                                            height: 300px;
                                            overflow: auto;
                                          ">
                                           <tr  *ngFor="
                                           let list of src?.services[
                                           selectedPillIndex
                                         ].ppo;
                                              index as ppoIndex
                                            " style="display: flex">
                                            <td class="W-90">
                                              <span [style]="
                                                  list.isSelected
                                                    ? 'color: #004745;font-weight: 600'
                                                    : ''
                                                ">{{ list.name }}</span>
                                            </td>
                                            <td class="W-20">
                                              <input [checked]="list.isSelected" type="checkbox"
                                                class="form-check-input right-aligned" [id]="ppoEpo + ppoIndex"
                                                [value]="list._id" (change)="
                                                  changeSelection(
                                                    list,
                                                    $event?.target?.checked,
                                                    i,
                                                    selectedPillIndex,
                                                    false,
                                                    'ppo',
                                                    src._id
                                                  )
                                                " />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </ng-container>
                                      <!-- <tbody id="myTable" *ngIf="src&& src.services &&src.services[selectedPillIndex]&& src?.services[selectedPillIndex]?.ppo?.length>0" style="width: 100%;display: block;border: 1px solid white;height: 300px;overflow: scroll;">
                                        <tr *ngFor="let ppo of src?.services[selectedPillIndex]?.ppo; let j = index">
                                          <td style="width: 80%" [style]="ppo.isSelected ? 'color: #004745;font-weight: 600' : ''">{{ppo.name}}
                                          </td>
                                          <td style="width: 20%">
                                            <input [(ngModel)]="ppo.isSelected" type="checkbox" class="form-check-input right-aligned" [id]="ppoEpo + j" [value]="ppo._id"
                                               (change)="changeSelection(ppo,$event?.target?.checked)" />
                                          </td>
                                        </tr>
                                      </tbody> -->
                                    </table>
                                  </div>
                                </div>
                                <!-- <div class="col-lg-1" style="width: 0;">&nbsp;</div> -->
                              
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--2ndpill-->
                        <div class="tab-pane fade" id="pills-tin" role="tabpanel" aria-labelledby="pills-tin-tab">
                          Profile
                        </div>
                        <!--3rdpill-->
                        <div class="tab-pane fade" id="pills-validation" role="tabpanel"
                          aria-labelledby="pills-validation-tab">
                          Revalidation
                        </div>
                        <!--4thpill-->
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                          aria-labelledby="pills-contact-tab">
                          Contact
                        </div>
                      </div>

                      <!--Nav Pills-->
                    </div>
                  </div>
                </div>
                <br />
                <div class="clearfix">&nbsp;</div>
                <div class="actions clearfix justify-content-end">
                  <ul role="menu" aria-label="Pagination">
                    <a class="btn custom-btn" routerLink="/information/cs/speciality" role="menuitem">Previous</a>
                    <button style="float: right" routerLink="/information/cs/order" class="btn custom-btn me-4"
                      [disabled]="nextDisabled()" (click)="nextBtn()">
                      Next
                    </button>
                  </ul>
                </div>
              </div>
              <div class="selected_service_bar col-lg-4">
                <div class="bg-whitebg">
                  <table class="table align-middle table-nowrap mb-0 table-responsive selected-services1">
                    <tbody>
                      <tr>
                        <td style="background: #004745 !important; color: #ffffff;">
                          <div class="font-text fw-700">Selected Payer(s)</div>
                        </td>
                      </tr>
                      <tr *ngFor="let src of data; let itemIndex = index">
                        
                        <td style="width: 20px; display: none"></td>
                        <td>
                          <h5 class="text-truncate mb-1">
                            <a href="javascript: void(0);" class="text-dark">{{
                              src.name
                              }}</a>
                            <i class="bx bx-x align-middle me-1" (click)="removeProvider(src._id)"></i>
                          </h5>
                          
                          <!-- selected  payer(s) display  -->
                          <div ngbAccordion>
                            <div ngbAccordionItem *ngFor="let service of data[itemIndex].services;let serviceIndex = index">
                              <h2 ngbAccordionHeader>
                                <button ngbAccordionButton>
                                  
                                {{ addSpaceBeforeCaps(service?.type) | titlecase}} - {{ service.name }} {{service?.otherText}}
                                </button>
                              </h2>
                              <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                  <ng-template>
                                    <!-- government selected payer(s)  -->
                                    <div *ngFor="
                                        let g of data[itemIndex].services[serviceIndex].gov;
                                        let gIndex = index
                                      " class="text-center d-flex">
                                      <p *ngIf="g.isSelected && g.prId == src._id" class="_color">
                                        <i class="bx bx-x pointer" (click)="removeEnrollment(g, service?._id, src?._id, 'x')"></i>
                                        {{ g.name }}
                                      </p>
                                    </div>
                                    <!-- end of the government selected payer(s)  -->

                                    <!-- <div *ngFor="
                                        let g of service.ppo;
                                        let gIndex = index
                                      " class="text-center d-flex">
                                      <p *ngIf="g.isSelected" class="_color">
                                        <i class="bx bx-x pointer" (click)="removePpoItem(gIndex, g)"></i>
                                        {{ g.name }}
                                      </p>
                                    </div> -->

                                    <!-- <ul>
                                      <li *ngFor="
                                          let payer of selectedPayers
                                        ">
                                        <i (click)="removeEnrollment(payer, service?._id, src?._id, 'x')" class="bx bx-x"></i>
                                        <span class="mb-0 _color">{{ payer.name }}</span>
                                      </li>
                                    </ul> -->

                                    <!-- ppo payer(s)  -->
                                    <ng-container *ngFor="
                                                                         let p of service.ppo;
                                                                         let pIndex = index
                                                                       ">
                                      <div *ngIf="p.isSelected && p.prId == src._id" class="text-center d-flex txt-wrap">
                                        <p class="_color">
                                          <i class="bx bx-x pointer" (click)="removeEnrollment(p, service?._id, src?._id, 'x')"></i>
                                          {{ p.name }}
                                        </p>
                                      </div>
                                    </ng-container>
                                    <!-- end of the ppo payer(s) -->

                                    <!-- hmo list  -->
                                    <div *ngFor="
                                        let h of service.hmo;
                                        let hIndex = index
                                      " class="text-center d-flex">
                                      <p *ngIf="h.isSelected" class="_color">
                                        <i class="bx bx-x pointer" (click)="removeItem(hIndex, h)"></i>
                                        {{ h.name }}
                                      </p>
                                    </div>
                                    <!-- end of the hmo list  -->
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end of the selected payer(s) display  -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card body -->
    </div>
    <!-- end card -->
  </div>
  <!-- end col -->
</div>
