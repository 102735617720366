import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  Group,
  PrerequisiteInput,
  Provider,
  ProviderEnrollmentDataData,
  ProviderEnrollmentInput,
  ProviderInput,
} from "../models/provider.model";
import { Observable, Subject, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProviderService {
  providers$ = new Subject();
  constructor(private http: HttpClient) {}

  async post(prerequisite: PrerequisiteInput) {
    this.http
      .post<any>(
        environment.serviceAPI + `/provider/prerequisite`,
        prerequisite
      )
      .subscribe((res) => {
        return res;
      });
  }

  async put(id: string, data) {
    this.http
      .put<any>(environment.serviceAPI + `/provider/prerequisite/` + id, data)
      .subscribe((res) => {
        return res;
      });
  }

  async putProvider(id: string, data) {
    this.http
      .put<any>(environment.mainServiceAPI + `/provider/` + id, data)
      .subscribe((res) => {
        return res;
      });
  }

  async add(provider: ProviderInput) {
    this.http
      .post<any>(environment.mainServiceAPI + `/provider/add`, provider)
      .subscribe((res) => {
        return res;
      });
  }

  ValidateNpinumber(npiNumber: any) {
    return this.http.get<Group[]>(
      environment.mainServiceAPI + "/npiproviders?npiNumber=" + npiNumber + ""
    );
  }

  getGroups() {
    return this.http.get<Group[]>(environment.mainServiceAPI + `/practice`);
  }

  getPracticeById(id: string) {
    return this.http.get<Group>(environment.mainServiceAPI + `/practice/` + id);
  }

  setProviders(data): any {
    this.providers$.next(data);
  }

  getProvidersData(): any {
    return this.providers$.asObservable();
  }

  getProviders() {
    return this.http.get<Provider[]>(environment.mainServiceAPI + `/provider`);
  }
  getPrerequisite(id: string) {
    return this.http.get<any>(
      environment.serviceAPI + "/providerprerequisite/" + id
    );
  }

  getProvider(id: string) {
    return this.http.get<any>(environment.mainServiceAPI + `/provider/` + id);
  }

  //GetMulitpleprovider
  getmultiprovider(ids) {
    return this.http.post<any>(
      environment.mainServiceAPI + `/provider/getmultiprovider`,
      ids
    );
  }

  postProviderEnrollment(input: ProviderEnrollmentInput): Observable<any> {
    return this.http.post<any>(
      environment.serviceAPI + `/providerenrollment/add`,
      input
    );
  }

  getProviderEnrollment() {
    return this.http.get<ProviderEnrollmentDataData>(
      environment.serviceAPI + `/providerenrollment/`
    );
  }

  checkNPIRegistryNumber(npiNumber: string) {
    return this.http.get<any>(
      environment.npiRegistryAPI + `&number=` + npiNumber
    );
  }

  getEnrollmentWithProviderid(id: string) {
    return this.http.get<any>(
      environment.serviceAPI + `/providerenrollment/provider/` + id
    );
  }

  getEnrollmentWithPracticeid(id: string) {
    return this.http.get<any>(
      environment.serviceAPI + `/providerenrollment/practice/` + id
    );
  }

  // fetch the providers as per the practise id
  getProvidersByPracticeId(id: string) {
    return this.http.get<any>(
      environment.mainServiceAPI + `/provider/practice/` + id
    );
  }

  // api for the triggering of the email
  triggerEmail(data: { toEmail; fromEmail; subject; body }) {
    return this.http.post<any>(
      environment.mainServiceAPI + `/user/custom-email`,
      data
    );
  }

  // api to get states
  getState(code: string) {
    return this.http
      .get<any>(environment.mainServiceAPI + `/state?code=` + code)
      .pipe(map((response) => response));
  }

  // api for getting the state list
  public getStateList() {
    return this.http
      .get<any>(environment.mainServiceAPI + "/state")
      .pipe(map((response) => response));
  }

  // get state by id
  public getStateById(id: string) {
    return this.http.get<any>(environment.mainServiceAPI + `/state/${id}`);
  }

  practiceCredentialServices() {
    return this.http.get<any>(
      environment.serviceAPI + `/practiceCredentialServices`
    );
  }

  credentialService(Id) {
    return this.http.get<any>(
      environment.serviceAPI + `/credentialService/` + Id
    );
  }

  credentialtype() {
    return this.http.get<any>(environment.serviceAPI + `/credentialtype/`);
  }

  updateproviderstatus(ids) {
    return this.http.post<any>(
      environment.mainServiceAPI + `/provider/updateproviderstatus/`,
      ids
    );
  }

  // update of the provider name
  public updateProviderName(
    id,
    firstname: string,
    lastname: string,
    email: string,
    practice,
    customerID
  ): Observable<any> {
    return this.http.put<any>(
      environment.mainServiceAPI + `/providerNamesUpdate/${id}`,
      {
        firstname: firstname,
        lastname: lastname,
        email: email,
        practice: practice,
        customer_id: customerID,
      }
    );
  }

  public updateProviderEmail(
    id,
    email: string,
  ): Observable<any> {
    return this.http.post<any>(
      environment.mainServiceAPI + `/provider/updateprovideremail`,
      {
        email: email,
        id: id,
      }
    );
  }

  getAddresstypes() {
    return this.http.get<any>(environment.serviceAPI + `/addresstype/`);
  }

  addPractice(paylaod) {
    return this.http.post<any>(
      environment.serviceAPI + `/practiceinfo/add`,
      paylaod
    );
  }

  getPracticeInfo(paylaod) {
    return this.http.get<any>(
      environment.serviceAPI + `/practiceinfo/practice/${paylaod}`
    );
  }
  getaddress(paylaod) {
    return this.http.get<any>(
      environment.serviceAPI + `/address/practice/${paylaod}`
    );
  }

  updatePractieInfo(id, payload) {
    return this.http.put<any>(
      environment.serviceAPI + `/practiceinfo/${id}`,
      payload
    );
  }

  addAddress(payLoad) {
    return this.http.post<any>(
      environment.serviceAPI + `/address/add`,
      payLoad
    );
  }

  addowerShip(payLoad) {
    return this.http.post<any>(
      environment.serviceAPI + `/ownershipinfo/addmany`,
      payLoad
    );
  }

  updateOwnerShip(id, payLoad) {
    return this.http.put<any>(
      environment.serviceAPI + `/ownershipinfo/${id}`,
      payLoad
    );
  }

  addServiceAddress(payLoad) {
    return this.http.post<any>(
      environment.serviceAPI + `/servicelocation/addmany`,
      payLoad
    );
  }

  updateServiceAddress(id, payLoad) {
    return this.http.put<any>(
      environment.serviceAPI + `/servicelocation/${id}`,
      payLoad
    );
  }

  getServiceAddress(payLoad) {
    return this.http.get<any>(environment.serviceAPI + `/servicelocation`);
  }

  getOwerShipInfo(payLoad) {
    return this.http.get<any>(environment.serviceAPI + `/ownershipinfo`);
  }

  updateAddress(id, payLoad) {
    return this.http.put<any>(
      environment.serviceAPI + `/address/${id}`,
      payLoad
    );
  }
}
