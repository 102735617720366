import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.scss']
})
export class TermsofserviceComponent implements OnInit{
constructor(){}

ngOnInit(){

}


}
