import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CredentialSpecialtyService {
  selectedSpecialtyInputs = new BehaviorSubject<any[]>([]);
  providerSpecialtyInputs = new BehaviorSubject<any[]>([]);
  constructor(private http: HttpClient) {}

  getServiceType() {
    return this.http.get<any>(environment.serviceAPI + `/credentialtype`);
  }

  getCredentialService() {
    return this.http.get<any>(environment.serviceAPI + `/credentialService`);
  }

  getCredentialServiceFiterByType(id: any) {
    return this.http.get<any>(
      environment.serviceAPI + `/credentialService/credentialtype/` + id
    );
  }

  saveCredentialService(data: any) {
    return this.http.post<any>(
      environment.serviceAPI + `/practiceCredentialServices/`,
      data
    );
  }
}
