import { ProviderService } from "./../../shared/services/provider.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
} from "@angular/core";
//import MetisMenu from 'metismenujs/dist/metismenujs';
import MetisMenu from "node_modules/metismenujs/dist/metismenujs";
import { EventService } from "../../core/services/event.service";
import { Router, NavigationEnd } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { ProjectService } from "src/app/shared/services/project.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  userType: string;
  private providerData: any = [];
  currentUser: any;
  currentHolder: string;
  menuItems = [];
  MenuLinks = ['/information/speciality','/information/providers','/information/enrollments','/information/order',
'/information/csproviders','/information/cs/speciality','/information/cs/enrollments','/information/cs/order','information/service'
  ]

  @ViewChild("sideMenu") sideMenu: ElementRef;

  constructor(
    public router: Router,
    public translate: TranslateService,
    public projectService: ProjectService,
    private providerService: ProviderService,
    public cookie:CookieService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
    this.getProjects();
    this.populateData();
  }
  ngOnInit() {    
    this.getProjects();
    this.populateData();
    this.currentUser = JSON.parse(this.cookie.get("user"));
    // console.log(this.currentUser)
    if ((this.currentUser?.usertype?.name).toUpperCase() == 'ADMIN') {
      this.currentHolder = "/dashboard/admin";
    }else{
      this.currentHolder = "/dashboard";
    }
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
    this.getProjects();
    this.populateData();
  }

  getProjects() {
    const currentUser = JSON.parse(this.cookie.get("user"));

    const practiceId = currentUser?.practiceData?._id;

    this.projectService.getAllProjects().subscribe((res: any) => {

      let orders = res
      orders = res.filter((order) => order.practiceid === practiceId)

      const user = JSON.parse(this.cookie.get("user"));
      this.userType = user?.usertype?.name.toLowerCase();
      if (this.userType?.includes("admin")) {
        this.userType = "admin";
      } else if (this.userType?.includes("staff") && orders?.length === 0) {
        this.userType = "first";
      } else if (this.userType?.includes("staff") && orders?.length > 0) {
        this.userType = "user";
      }
      if (orders?.length === 0) {
        
        const menuItems = this.menuItems.filter((menu) => {
          return menu.link !== "/projects";
        });
        this.menuItems = menuItems;   
             
      }
      // else {
      //   this.menuItems.push(MENU[3])http://localhost:4200/information
      // }
      this.initialize();
      this._scrollElement();
    });
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  routeBtn(route){
    // console.log(route);
    this.router.navigate([`/${route}`])
    .then(() => {
      if(route == '/dashboard'){
        window.location.reload();
      }
    });
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass("mm-active");
    this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    const hostUrl = this.MenuLinks.find(res=> res == window.location.pathname);
      if(window.location.pathname == hostUrl){
        var itemIndex = 2
      }else{
        var itemIndex = paths.indexOf(window.location.pathname);
        links[2]?.classList.remove("active");
      }
      if (itemIndex === -1) {
        const strIndex = window.location.pathname.lastIndexOf("/");
        const item = window.location.pathname.substr(0, strIndex).toString();
        menuItemEl = links[paths.indexOf(item)];
      } else {
        menuItemEl = links[itemIndex];
      }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    const currentUser = JSON.parse(this.cookie.get("user"));
        // console.log(this.userType);
    this.menuItems = MENU.filter((menu) => {
      return menu.view.includes(this.userType);
    });

    // console.log(this.menuItems);
    
    if(currentUser?.practiceData?.npitype[0].name == "Individual"){
      this.menuItems = this.menuItems.map((res, index)=>{
        if(res.label !== 'MENUITEMS.PROVIDERS.TEXT'){
          return res
        } 
      }); 
      // console.log(this.menuItems);       
    };
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item?.subItems !== undefined ? item?.subItems.length > 0 : false;
  }

  populateData() {
    const currentUser = JSON.parse(this.cookie.get("user"));
    if (currentUser?.practiceData) {
      const practiceId = currentUser?.practiceData?._id;
      this.providerService.getPrerequisite(practiceId).subscribe({
        next: (data: any) => {
          this.providerData = data?.data;
        },
        error: () => {
          this.providerData = []
        },
        complete: () => {

          if (this.providerData?.length == 0) {
            const menuItems = this.menuItems.filter((menu) =>
              menu.link !== "/information/service"
            );
            this.menuItems = menuItems;
          }
        }
      });
    }
  }
}
