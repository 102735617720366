import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Order, OrderInput } from "../models/order.model";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  constructor(private http: HttpClient) { }

  getAllProjects() {
    return this.http.get<Order[]>(
      environment.serviceAPI + `/orders`
    );
  }

  get(id: string) {
    return this.http.get<Order>(
      environment.serviceAPI + `/orders/` + id
    );
  }

  post(order: OrderInput) {
    return this.http
      .post<any>(environment.serviceAPI + `/orders`, order)
      .subscribe((res) => {
        return res;
      });
  }

  AddInformationAPICall(body:any){
    return this.http.post<any>(
      environment.mainServiceAPI + `/upload`,
      body
    );
  }

  put(id: string, order: OrderInput) {
    return this.http
      .post<any>(environment.serviceAPI + `/orders/` + id, order)
      .subscribe((res) => {
        return res;
      });
  }

  delete(id: string) {
    return this.http.delete<Order>(
      environment.serviceAPI + `/orders/` + id
    );
  }


  // get project by the practiceId
  public getProjectByPracticeId(practiceId:string){
    return this.http.get<Order[]>(
      environment.serviceAPI+`/project/practice/`+practiceId
    )
  }



  // adding of the field name
  public addFieldData(data:any){
    return this.http.post<any>(`${environment.mainServiceAPI}/fieldnamedata/add`,data)
  }


  // updating of the filed name by id
  public updateFiledData(id:number,data:any){
    return this.http.put<any>(`${environment.mainServiceAPI}/fieldnamedata/${id}`,data);
  }

  // get all field name data
  public getAllFieldNameData(){
    return this.http.get<any>(`${environment.mainServiceAPI}/fieldnamedata`)
  }


  // get field name by id
  public getFieldNameById(id:number){
    return this.http.get<any>(`${environment.mainServiceAPI}/filednamedata/${id}`)
  }
}
