// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const origin = window.location.origin;
const envSetup = true;

const HTTP  = 'https://';
var DevHTTP  = '';

var realm = 'aaamb'
var keycloakClientId = 'aaamb-web-client'


if(origin.includes('dev')){
  DevHTTP  = HTTP + 'dev.';
  realm = 'dev';
  keycloakClientId = 'dev-aaamb-web-client';
} else if(origin.includes('uat')){
  DevHTTP  = HTTP + 'uat.';  
  realm = 'uat';
   keycloakClientId = 'uat-aaamb-web-client';
} else if(origin.includes('localhost')){
  DevHTTP  = HTTP + 'dev.';
  realm = 'dev';
  keycloakClientId = 'dev-aaamb-web-client';
} else {
  DevHTTP  = HTTP + 'app.';
}

export const environmentNew = {
  production: false,
  
  //apis
  serviceAPI: DevHTTP + 'credentialtoday.com/api',            //ct

  mainServiceAPI: DevHTTP + 'admin.aaamb.com/api',           //admin
  mainServiceViewAPI:  DevHTTP + 'admin.aaamb.com/api/view/', //adminView
  
  kcapiHost: DevHTTP + 'aaamb.com/api/kc-service',                      //sso
  mainSubscriptionAPI: DevHTTP + 'aaamb.com/api/shopping',    //sso - shoping
  SubscriptionAPI: DevHTTP + 'aaamb.com/api/products',       //sso - products
  ShoppingAPI: DevHTTP + 'aaamb.com/api/shopping/shopping/allsubscriptions',  


  //web
  kcHost: HTTP + 'auth.aaamb.com',                           //keyclock
  logoutUrl: DevHTTP + 'credentialtoday.com/login',           //ct
  

  npiRegistryAPI: 'https://npiregistry.cms.hhs.gov/api/?version=2.1',
  adminMail:'credentialtoday@aaamb.com', // for contact email triggering.,

  realm: realm,
  keycloakClientId:keycloakClientId,
  aaambRedirectURl: 'app.aaamb.com'
};


export const environmentOld = {
  production: false,
  serviceAPI: 'http://3.6.5.222:8005',
  //mainServiceAPI: 'http://localhost:8010',
  mainServiceAPI: 'http://3.6.5.222:8010',
  SubscriptionAPI: 'http://3.6.5.222:8002',
  mainSubscriptionAPI: 'http://3.6.5.222:8003',
  mainServiceViewAPI: 'http://3.6.5.222:8010/view/',
  kcapiHost:'http://3.6.5.222:3000',
  kcHost:'http://3.6.5.222:8080',
  npiRegistryAPI: 'https://npiregistry.cms.hhs.gov/api/?version=2.1',
  adminMail:'credentialtoday@aaamb.com', // for contact email triggering.,
  mainlocalhostUrl: 'http://localhost:8010',
  localhostUrl :'http://localhost:8005',
  logoutUrl:'http://3.6.5.222:8008//login',
  realm: realm,
  keycloakClientId:keycloakClientId
};

export const environmentLocal = {
  production: false,
  serviceAPI: 'http://localhost:8005',
  mainServiceAPI: 'http://localhost:8010',
  SubscriptionAPI: 'http://localhost:8002',
  mainSubscriptionAPI: 'http://localhost:8003',
  mainServiceViewAPI: 'http://localhost:8010/view/',
  kcapiHost:'http://localhost:3000',
  kcHost: HTTP + 'auth.aaamb.com',  
  npiRegistryAPI: 'https://npiregistry.cms.hhs.gov/api/?version=2.1',
  adminMail:'credentialtoday@aaamb.com', // for contact email triggering.,
  mainlocalhostUrl: 'http://localhost:8010',
  localhostUrl :'http://localhost:8005',
  logoutUrl:'http://localhost:4200/login',
  ShoppingAPI: DevHTTP + 'aaamb.com/api/shopping/allsubscriptions',
  realm: realm,
  keycloakClientId:keycloakClientId
};

export const environment = envSetup ? environmentNew : environmentLocal;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
