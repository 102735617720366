import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CreateOrderInput } from "../models/order.model";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private http: HttpClient) {}

 createOrders(input: CreateOrderInput) {
    return this.http
      .post<any>(environment.serviceAPI + `/orders`, input);
  }

  getAllOrders() {
    return this.http
      .get<any>(environment.serviceAPI + `/orders`);
  }
}