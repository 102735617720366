import { Component , OnInit} from '@angular/core';
import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(
    private loader: LoaderService,
  ) {}

  ngOnInit() {
    this.loader.isLoading.next(true);
    setTimeout(() => {
      this.loader.isLoading.next(false);
    }, 1500)
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
