<div class="container-fluid p-0 body_background">
    <div class="row g-0">
        <div class="col-xl-12">
            <div class="auth-full-bg">
                <div class="w-100 h-100">
                    <!--Header-->
                    <div class="header">
                        <div class="logo">
                            Credentials Today
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                            <h1 class="_color">Terms Of Service</h1>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <p><strong>Security of Data:</strong> All personal, professional and financial data
                                    related to the credentialing project of the Client, will be kept in strictest
                                    confidence by Credential Today /AAAMB. It is Credential Today /AAAMB’s policy to
                                    protect the security of Client data to the maximum extent possible and in accordance
                                    with the current Government Guidelines and online Security protocols.
                                </p>
                                <p><br /><strong>Use of Confidential Information:</strong> Except as directly necessary
                                    for performing its obligation Credential Today/AAAMB will not use, reproduce or
                                    disclose to others any such Confidential Information without the prior written
                                    consent of the Disclosing Party. The Client agrees that the information supplied to
                                    the Credential Today/AAAMB is accurate, complete and truthful. That the client will
                                    provide Credential Today/AAAMB with all copies of documents and information required
                                    by payers, and the copies of the correspondence from Third Party Payers. Credential
                                    Today/AAAMB will not change or alter any information supplied by the client.
                                    Credential Today/AAAMB will maintain a record of documents provided for a period of
                                    3 years and will forward to the payers in a timely manner as long as provided by the
                                    Client in a timely manner.</p>
                                <p><br /><strong>Limitation of Liability (Disclaimer):</strong> Credential Today /AAAMB
                                    will use reasonable care in processing credentialing and contracting tasks for the
                                    Client. Credential Today /AAAMB will not be held responsible for errors caused by
                                    Client or Client’s representatives and staff, payer or payer representatives.
                                </p>
                                <p><br /><strong>Termination:</strong> This agreement shall be terminated, either with
                                    or without cause, by Client after giving thirty (30) days prior written notice to
                                    Credential Today /AAAMB. However, Credential Today /AAAMB may terminate the Service
                                    agreement, either with or without causes only giving after sixty (60) days prior
                                    written notice to Client or allow Client a suitable time frame to make alternative
                                    arrangement for the credentialing needs. Upon termination of this agreement by
                                    either party, Client agrees to promptly pay all outstanding charges owed as of the
                                    final termination date.
                                </p>
                                <p><br /><strong>Non Liability:</strong> Credential Today /AAAMB shall not be liable for
                                    the failure to perform any of its obligations under these terms when such failure is
                                    caused by conditions beyond the reasonable control of said party or by accident, act
                                    of God, repairs, equipment failures, strike or labor disturbances.
                                </p>
                                <p><br /><strong>Compensation:</strong> Client will pay Credential Today /AAAMB as
                                    compensation for the service provided in accordance with the following: Charges for
                                    credentialing service rendered by the Credential Today /AAAMB shall be auto-ran at
                                    the time of initiation of requested credentialing project and monthly, based on
                                    Credential Today /AAAMB service fees.</p>
                                <p><br /><strong>Authorization:</strong> By accepting these terms, I hereby authorize
                                    Credential Today /AAAMB representative to execute all documents pertaining to the
                                    credentialing management of my practice in accordance to the outline service
                                    request.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
        <div class="row  m-t-20">
            <div class="col-md-6 col-sm-12 col-lg-6">
                <p class="copy_rights_styles">Copyright ©2024 AAAMB LLC. All rights reserved</p>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-6 text-end">
                <div class="m-r-3p">
                    <small><a routerLink="/terms-service" target="_blank" class="footer_links font-12">Terms of
                            Services</a></small>
                    <small><a routerLink="/privacy-policy" target="_blank" class="font-12"
                            style="margin-left: 15px;">Privacy Policy</a></small>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>