import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../core/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "app-topBar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopBarComponent implements OnInit {
  element;
  cookieValue;
  flagValue;
  countryName;
  valueSet;
  currentUser: any;
  practiceName: string = "";
  LogoUrl : any;
  isLogo: boolean = true;
  

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private keyCloakService:KeycloakService,
    
  ) {
    // this._userService.getMessage.subscribe((res)=>{
    //   const user = JSON.parse(this.cookie.get("userDetails"));
    //   localStorage.setItem("userDetails", JSON.stringify(user));
    //   this.currentUser = JSON.parse(this.cookie.get("userDetails"));
    // })
  }
  

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {

    const currentUser = JSON.parse(this._cookiesService.get("user"))?.practiceData;
    if (currentUser) {
      this.practiceName = currentUser?.name;
      this.LogoUrl = environment.mainServiceAPI+ '/' + currentUser.logo;
    }
    if(currentUser?.logo){
      this.isLogo = false;
      this.LogoUrl = environment.mainServiceAPI+ '/' + currentUser.logo;
    }else{
      this.isLogo = true
    }
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.currentUser = JSON.parse(this._cookiesService.get("userDetails"));
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagValue === undefined) {
        this.valueSet = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagValue = val.map((element) => element.flag);
    }
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  showDropdown(){
    const box = document.getElementById('dropdown');
    box.classList.add('show');
  }

  settings(){
    this.router.navigate(["/settings"]);
    const box = document.getElementById('dropdown');
    box.classList.remove('show');
  }

  close(event:any){
    // event.target.closest(".dropdown-menu").removeClass;
    const box = document.getElementById('dropdown');
    box.classList.remove('show');
  }

  getRandomColor() {
    if(this.currentUser?.firstname?.length > 6){
      return '#004745' 
    }else{
      return '#c12228'
    }
  }
  /**
   * Logout the user
   */
  logout() {
    this.router.navigate(['/login']);
    this._cookiesService.deleteAll();
    this.keyCloakService.logout(environment.logoutUrl);
  }

  getPracticeName(name) {
    return name
    .split(' ')
    .slice(0, 2)
    .map(n => n[0]?.toUpperCase())
    .join('');
  }
}
